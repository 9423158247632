<template>
    <section class="home">
        <div class="container text_center banner">
            <div class="col mb-5">
                <h1><img src="@/assets/chanel-beauty-logo.svg" alt=""><span>CHANEL {{$t('fnb-homepage-title')}}</span></h1>
                <p class="mb-0 mx-auto col_md_10 col_lg_7" v-html="$t('fnb-homepage-intro')"></p>
            </div>
        </div>
        <picture class="img_cover banner_img">
            <source media="(min-width:1280px)" srcset="@/../public/img/content/hp-beauty-desk-x2.webp">
            <source media="(min-width:768px)" srcset="@/../public/img/content/hp-beauty-desk.webp">
            <source media="(min-width:480px)" srcset="@/../public/img/content/hp-beauty-mob-x3.webp">
            <img src="@/../public/img/content/hp-beauty-mob-x2.jpg" alt="" loading="lazy" style="width:auto;">
        </picture>
        <div class="filter_bar is_sticky">
            <div class="container">
                <div class="col d_flex j_space_btw a_center">
                    <service-filters
                        :thematics="getThematics"
                        @update:type="onServiceTypeFilterChange"
                        @update:thematic="onThematicFilterChange"
                    />
                    <span v-if="hasClientAccount">
                        <a href="" class="btn btn_bb_black fs_0 hide_mb">{{ $t('fnb-homepage-client-account-label') }}</a>
                        <a href="" class="btn btn_bb_black fs_0 hide_md">{{ $t('fnb-homepage-client-account-label-small') }}</a>
                    </span>
                </div>
            </div>
        </div>
        <transition name="component-fade">
            <loading-dots v-if="!hasServices" class="text_center"></loading-dots>

            <div v-else>
                <!-- Group Services Loops -->
                <services-group
                    v-for="(thema, tIndex) in thematicServicesList"
                    :key="`svcGroup-${thema[0].thematic ? thema[0].thematic.code : ''}-${serviceTypeFilter}-${tIndex}`"
                    :thematic="thema[0].thematic"
                    :services="thema"
                    :singleBoutiqueId="singleBoutiqueId"
                    :singleBoutiqueSlug="singleBoutiqueSlug"
                    :boutiquesOnly="boutiquesOnly"
                    :wholesaleGroupSlug="wholesaleGroupSlug"
                    :odd="tIndex % 2 === 1"
                ></services-group>
                <!-- End Group Services Loop -->
            </div>

        </transition>
        <div class="mb_7 mt_7 mt_md_9 pt-md-4 text_center">
            <button @click="onBackToTopClick" class="nbs hn_style text-11 c_black">
                <img src="@/assets/light-arrow-up.svg" alt="" class="mb-4 mx-auto d_block">
                {{ $t('common-back-to-top') }}
            </button>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { EventBus } from '@/utils/EventBus';
import Page from '@/views/Page.js';
import API from '@/api.js';
import { Divisions } from '@/const/Divisions';
import VueScrollTo from 'vue-scrollto';
import LoadingDots from '@/components/common/LoadingDots.vue';
import ServicesGroup from '@/components/templates/beauty/homebeauty/ServicesGroup.vue';
import ServiceFilters from '@/components/templates/beauty/homebeauty/ServiceFilters.vue';

export default {
    name: 'HomeBeauty',
    metaInfo: function() {
        const meta = {
            title: this.$t('homepage-meta-title'),
            meta: [
                { name: 'description', content: this.$t('homepage-meta-description') }
            ]
        };
        if (this.boutiquesOnly || this.singleBoutiqueSlug) {
            // If scoped to Boutiques only, define a canonical URL to the generic homepage
            meta.link = [
                { rel: 'canonical', href: window.location.origin }
            ];
        }
        return meta;
    },
    mixins: [
        Page
    ],
    components: {
        LoadingDots,
        ServicesGroup,
        ServiceFilters
    },
    data: () => ({
        servicesLoading: false,
        singleBoutiqueId: null,
        scrollToCancel: null,
        // TODO: 
        // Temporary de-activation of client account link
        // Should be replaced by actually implemented client account link.
        hasClientAccount: false
    }),
    watch: {
        $route(to, from) {
            // Force reload the service list in case of market change,
            // as the market change can occur without re-mounting the full page.
            if (to.params.market !== from.params.market) {
                this.fetchServices();
            }
        }
    },
    computed: {
        ...mapState('service', [
            'serviceTypeFilter'
        ]),
        ...mapGetters('service', {
            getServices: 'getUnhiddenFilteredThematicServices',
            getThematics: 'getThematics',
            getNormalizedServiceTypes: 'getNormalizedServiceTypes'
        }),
        /**
         * For each service group (as a thematic),
         * map the type to the normalized type
         */
        thematicServicesList() {
            return this.getServices.map((thema) => {
                return thema.map((service) => {
                    return {
                        ...service,
                        type: this.getNormalizedServiceTypes(service)
                    };
                });
            });
        },
        /**
         * Scoped on Boutiques only 
         * @return {boolean} true wether the route is specific to CHANEL Boutiques
         */
        boutiquesOnly() {
            return this.$route.name === 'homeboutiques';
        },
        /**
         * Scoped for single Boutique only
         * @return {string|boolean} single boutique slug like 'chanel-paris-51-montaigne', or false if not scoped to single boutique
         */
        singleBoutiqueSlug() {
            return (this.$route.params.boutique && !this.boutiquesOnly) ? this.$route.params.boutique : '';
        },
        /**
         * Scoped on wholesale group
         */
        wholesaleGroupSlug() {
            return this.$route.name === 'homewholesalegroup' ? this.$route.params.wholesaleGroupSlug : '';
        },
        /**
         * 
         */
        hasServices() {
            return this.getServices && this.getServices.length > 0;
        },
        /**
         * Build the route query string params.
         * Avoid having empty params if they are unnecessary.
         */
        routeQuery() {
            const query = {};
            if (this.wholesaleGroupSlug) {
                query.wholesale = this.wholesaleGroupSlug;
            }
            return query;
        }
    },
    created: function() {
        // Check boutique existence if a single boutique slug is provided. 
        // This could be moved into a beforeRouteEnter navigation guard, 
        // but then we would lose the current URL.
        if (this.singleBoutiqueSlug) {
            API.getBoutiqueStatus(this.singleBoutiqueSlug)
                .then((result) => {
                    // All OK: store the chanelId for later navigation, and continue with services.
                    this.singleBoutiqueId = result.chanelId;
                })
                .catch((error) => {
                    EventBus.$emit('404-error');
                });
        }
        // Load services anyway
        this.fetchServices();
    },
    mounted: function() {
        // Reset service filters on opening
        this.setThematicFilter();
        this.setServiceTypeFilter();
        //
        this.$Tracker.pageView({
            page_name: 'homepage',
            virtual_page_url: '/homepage'
        });
    },
    methods: {
        ...mapActions('service', [
            'fetchServiceList',
            'setThematicFilter',
            'setServiceTypeFilter'
        ]),
        /**
         * Load the services list from API.
         */
        fetchServices() {
            this.servicesLoading = true;
            this.fetchServiceList({ divisionCode: Divisions.FNB.apiCode })
                .finally(() => {
                    this.servicesLoading = false;
                    // Page is complete: trigger render event
                    this.rendered();
                });
        },
        /**
         * 
         */
        onBackToTopClick() {
            this.scrollToCancel = VueScrollTo.scrollTo('body', 300);
        },
        onThematicFilterChange(value) {
            this.setThematicFilter(value);
        },
        onServiceTypeFilterChange(value) {
            this.setServiceTypeFilter(value);
        },
        /**
         * 
         */
        trackButtonClick(description, label = null, action = null) {
            this.$Tracker.event({
                eventcategory: description,
                eventlabel: label,
                eventaction: action
            });
        }
    }
};
</script>
