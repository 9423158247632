import axios from 'axios';
import md5 from 'md5';
import Config from '@/utils/config.js';
import { v4 as uuidv4 } from 'uuid';

/**
 * API access point 
 */
class _API {
    /**
     * 
     */
    constructor() {
        // Create raw Axios instance for first calls 
        this._axios = axios.create();
        this._locale = '';
        this._shortLocale = '';
        this._market = '';

        this._uuid = '';
        this._contentBaseURL = '';
        this._APIRootURL = '';
        this._APIBaseURL = '';
    }

    /**
     * 
     */
    init() {
        // Generate user unique ID and store to local storage
        this._uuid = this.restoreFromCache('uuid');
        if (!this._uuid) {
            this._uuid = uuidv4();
            this.storeToCache('uuid', this._uuid, 60 * 60 * 24);
        }
        this._contentBaseURL = '';
        this._APIRootURL = Config.getApiRootUrl();
        this._APIBaseURL = `${this._APIRootURL}/api`;
        // Re-create Axios instance with required headers 
        this._axios = axios.create(
            {
                headers: {
                    'Ocp-Apim-Subscription-Key': Config.getApiSubscriptionKey(),
                    SURMESURE_UNIQUE_ID: this._uuid
                }
            }
        );
        // axios.defaults.headers.common['Ocp-Apim-Subscription-Key'] = Config.getApiSubscriptionKey();
    }

    /**
     * Set market 
     * @param {String} market new market string, for example 'fr'
     */
    setMarket(market) {
        this._market = market;
    }

    /**
     * Set locale 
     * @param {String} locale new locale string, for example 'fr_FR'
     */
    setLocale(locale) {
        this._locale = locale;
        this._shortLocale = locale?.split('_')[0] || locale;
    }

    /**
     * Load Front-End configuration. 
     * This method does not require neither baseURL init nore subscription key header. 
     * Called before any other API call. 
     * Does not depend on the locale.
     */
    getConfiguration(configurationFileUrl = '/config.json') {
        return this._get(configurationFileUrl, false, false);
    }

    /**
     * Load i18n-specific configuration
     */
    getI18nConfiguration() {
        // return this._get('/api-mockup/countries.json', false, false);
        return this._get(this._APIBaseURL + '/countries?&referent=true&deployed=true', true, false);
    }

    /**
     * Load Front-End translation, for whole site.
     * Locale is implicitely defined with GET request, and doesn't need to be explicit here.
     */
    getI18n() {
        // return this._get('/api-mockup/i18n/fr.json', false);
        return this._get(this._APIRootURL + '/i18n?modules=fo', true);
    }

    /**
     * Load editorial content from CMS 
     */
    getServiceList(divisionCode) {
        // return this._get('/api-mockup/services.json', true);
        divisionCode = divisionCode || Config.getOption('defaultDivisionCode');
        return this._get(this._APIBaseURL + `/services?division=${divisionCode}&websiteAvailability=true`, true);
    }
    
    /**
     * Load the complete detailed products list 
     */
    getProducts() {
        // return this._get(`/api-mockup/product-variant_${this._shortLocale}.json`, true);
        return this._get(this._APIBaseURL + '/products/variants', true);
    }

    /**
     * Return a single product details
     * @param {int} sku 
     */
    getProduct(sku) {
        return this._get(this._APIBaseURL + '/products/variants/' + sku, true);
        // return this._get(`/api-mockup/product-variant_${this._shortLocale}.json`, false);
    }

    getFAQ() {
        return this._get('/api-mockup/faq.json');
    }

    getHealthCheck() {
        return this._axios.get(this._APIBaseURL + '/200')
            .then(function(response) {
                return response;
            })
            .catch(function(error) {
                console.log('API Error: ', error);
                return error;
            })
            .finally(function() {

            });
    }

    /**
     * Check the existence of the given Boutique 
     * @param {string} boutiqueSlug CHANEL boutique SEO name, for example '51montaigne'
     * @returns {boolean} 200 OK if the boutique exists
     */
    getBoutiqueStatus(boutiqueSlug) {
        return this._get(this._APIBaseURL + '/boutiques/' + boutiqueSlug);
        // 200 - OK
        // return this._get('https://run.mocky.io/v3/6cd19e02-13ff-4f63-8c9d-f02d239b23cb');
    }

    /**
     * Return all available timeslots and POS details for given params
     * - lat: geolocation point latitude
     * - lng: geolocation point longitude
     * - pos: the POS id if we want availabilities for this single POS
     * - boutiquesOnly: restrict on CHANEL boutiques
     * - wholesaleType: wholesale boutique type, for example nocibe|sephora|marionnaud
     * - type: STORE|VIRTUAL for filtering by instore or virtual appointments only
     * - serviceId: the service ID
     * @param {object} params 
     */
    getAvailabilities(params) {
        let defaultParams = {
            //
        };
        params = { ...defaultParams, ...params };
        // return this._get('/api-mockup/slots.json', false);
        return this._get(this._APIBaseURL + '/slots?' + this._serialize(params));
    }

    /**
     * Fetch a list of Point of Sales
     * @param {object} params 
     * @returns {object} paginated list of Point of Sales Results
     */
    getPointOfSales(params) {
        let defaultParams = {
            type: 'BOUTIQUE'
        };
        params = { ...defaultParams, ...params };
        // return this._get('/api-mockup/boutiques.json', false);
        return this._get(this._APIBaseURL + '/boutiques?' + this._serialize(params));
    }

    /**
     * Retrieve booking details 
     * @param {int} bookingId 
     * @param {string | null} email
     */
    getBookingDetails(bookingId, email = null) {
        // return this._get('/api-mockup/booking-details.json');
        // return this._get(this._APIBaseURL + '/bookings/' + bookingId);
        let url = this._APIBaseURL + `/bookings/${bookingId}`;
        if (email) url = `${url}?&email=${email}`;
        return this._get(url);
    }

    /**
     * Retrieve digital booking details 
     * @param {string} loginKey - The booking hash key 
     */
    getDigitalBookingDetails(loginKey) {
        return this._get(this._APIBaseURL + '/you-cam/' + loginKey);
        // 200 - OK 
        // return this._get('https://run.mocky.io/v3/6d275130-1b69-45da-86b8-6ee817c5419a');
        // 400 - TOO_EARLY
        // return this._get('https://run.mocky.io/v3/9440a406-562e-4e9a-ba1f-66dd4d7e24ef');
        // 400 - TOO_LATE
        // return this._get('https://run.mocky.io/v3/941a52a8-bde5-47ad-80a1-cd7e4877dda2');
        // 400 - EMAIL_INVALID
        // return this._get('https://run.mocky.io/v3/58f615a7-70c4-4f02-8eca-34d048512b47');
    }

    /**
     * Retreive the Voice Of Client status for the given booking
     * @param {string} loginKey - The booking hash key
     */
    getVOCBookingStatus(loginKey) {
        return this._get(this._APIBaseURL + '/voc/' + loginKey);
    }

    /**
     * Get the Voice Of Client form data
     */
    getVOCForm() {
        return this._get('/api-mockup/voc.json', false);
    }

    /**
     * Post all informations for user to request a paid service appointment
     * @param {Object} formData JSON object
     */
    postPaidBookingRequest(formData) {
        formData.origin = 'WEB';
        // return this._get('/api-mockup/booking-request-confirm.json');
        return this._post(this._APIBaseURL + '/booking-request', formData, false);
    }

    /**
     * Post all informations to book an apointment, including user image, in multipart form-data
     * @param {FormData} formData 
     */
    postMultipartBookingRequest(formData, bookingId) {
        formData.set('origin', 'WEB');
        const options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        return this._post(this._APIBaseURL + '/bookings/' + bookingId, formData, false, options);
    }

    /**
     * 
     * @param {object} formData 
     */
    postContactRequest(formData) {
        return this._post(this._APIBaseURL + '/contact', formData, false);
    }

    /**
     * 
     * @param {object} formData 
     */
    postLetMeKnowRequest(formData) {
        return this._post(this._APIBaseURL + '/letmeknow', formData, false);
    }

    /**
     * Send the user's favorite products 
     * @param {string} loginKey - The booking hash key
     * @param {array} skuList 
     */
    postFavoriteProducts(loginKey, skuList) {
        let data = {
            variants: skuList
        };
        return this._put(this._APIBaseURL + '/you-cam/' + loginKey, data);
    }

    /**
     * Post a single product recommanded to the user by the Beauty Advisor 
     * @param {string} loginKey - The booking hash key
     * @param {string} sku 
     */
    postRecommandedProduct(loginKey, sku) {
        let data = {};
        return this._post(this._APIBaseURL + '/you-cam/' + loginKey + '/variant/' + sku, data, false);
    }

    /**
     * Post user's responses to the Voice Of Client form
     * @param {object} formData 
     */
    postVOCResponses(loginKey, formData, version) {
        let data = {
            response: JSON.stringify(formData),
            version
        };
        return this._post(this._APIBaseURL + '/voc/' + loginKey, data, false);
    }

    /**
     * Post the presence confirmation request,
     * sent by Advisor to confirm client presence, post-booking
     * @param {String} token the API-provided token
     */
    postPresenceToken(token) {
        const data = {
            token
        };
        // 200: presence true
        // return this._post('https://run.mocky.io/v3/99730a6c-2e48-4606-8720-1e724a66c6a9', data, false);
        // 200: presence true, with purchase
        // return this._post('https://run.mocky.io/v3/7b96c2b5-e78c-485f-b8c8-f4b6eac0f260', data, false);
        // 200: presence false
        // return this._post('https://run.mocky.io/v3/c875e9c5-165e-42c2-8606-1b3a16e1b23c', data, false);
        // 400: too early
        // return this._post('https://run.mocky.io/v3/5e24337b-353a-4dc7-bb8d-aa7a67bf875f', data, false);
        return this._post(this._APIBaseURL + '/bookings/presence', data, false);
    }

    /**
     * Update an existing booking
     * @param {string} targetBookingId new slot id
     * @param {string} originalBookingId the original booking id 
     * @param {string} email the original booking user's email
     * @returns 
     */
    updateBooking(targetBookingId, originalBookingId, email) {
        const data = {
            clientEmail: email,
            targetBookingId
        };
        console.log('API.updateBooking:');
        // return this._get('/api-mockup/booking-confirm.json');
        // return this._get('https://run.mocky.io/v3/3d9a145f-ef06-4161-9732-e8f2cea052ec');
        return this._put(`${this._APIBaseURL}/bookings/${originalBookingId}`, data);
    }
    
    /**
     * Post the attendance confirmation request,
     * sent by Client to confirm his future presence, pre-booking
     * @param {String} token the API-provided token
     */
    postAttendanceToken(token) {
        const data = {
            token
        };
        return this._post(this._APIBaseURL + '/bookings/client-confirmation', data, false);
    }

    /**
     * Delete a booking
     * @param {int} bookingId 
     * @param {string} email 
     */
    deleteBooking(bookingId, email) {
        let url = this._APIBaseURL + '/bookings/' + bookingId + '?&email=' + encodeURIComponent(email);
        // let url = 'https://run.mocky.io/v3/6502f168-5ae9-4a30-af90-b7643b7eed29';
        let rqOptions = {};
        rqOptions.validateStatus = function(status) {
            // Allow various status as 'valid'
            return (status >= 200 && status < 300) || status === 400;
        };
        return this._axios.delete(url, rqOptions)
            .then(function(response) {
                return response.data;
            })
            .catch(function(error) {
                console.log('API Error: ', error);
                throw error;
            });
    }

    /**
     * Generic GET method, calling back-end API. 
     * @param {URL} url 
     */
    _get(url, useCache = false, addRequestOptions = true) {
        // Add locale to request options
        let rqOptions = {};
        if (addRequestOptions) {
            rqOptions = {
                params: {
                    // Send the two-letters locale, for example 'en' out of 'en_UK'
                    locale: this._shortLocale,
                    country: this._market
                }
            };
        }
        rqOptions.validateStatus = function(status) {
            // Allow various status as 'valid'
            return (status >= 200 && status < 300) || status === 400;
        };
        let key = API.createCacheKey(url, rqOptions);

        let data;
        if (useCache) {
            data = API.restoreFromCache(key);
            if (data) {
                return new Promise((resolve, reject) => {
                    data.fromCache = true;
                    resolve(data);
                });
            }
        }

        return this._axios.get(url, rqOptions)
            .then(function(response) {
                const result = response.data;
                if (response.status === 200 && useCache && !result.error) {
                    // Store reponse into cache
                    API.storeToCache(key, result);
                }
                return result;
            })
            .catch(function(error) {
                console.log('API Error: ', error);
                throw error;
            });
    }

    /**
     * Generic POST method, sending data to back-end API
     * @param {URL} url 
     * @param {Object} params 
     */
    _post(url, params, useObject = true, options = {}) {
        // Serialize locale & country after URL 
        let urlOptions = this._serialize({
            locale: this._shortLocale,
            country: this._market
        });
        url = url + (url.includes('?') ? `&${urlOptions}` : `?${urlOptions}`);

        let requestConfig = { ...options };
        requestConfig.validateStatus = function(status) {
            // Allow various status as 'valid'
            return (status >= 200 && status < 300) || status === 429 || status === 400;
        };
        return this._axios.post(url, params, requestConfig)
            .then(function(response) {
                const result = response.data;
                if (response.status === 200) {
                    return useObject ? result.object : result;
                } else {
                    return result;
                }
            }).catch(function(error) {
                console.log('API Error: ', error);
                // Reject a promise instead of throwing error,
                // so we can preserve all response parameters (status code, message, etc)
                return Promise.reject(error);
            });
    }

    /**
     * Generic PUT method, sending data to back-end API
     * @param {URL} url 
     * @param {Object} params request data to be included as payload
     * @param {Object} options request options (headers and so) 
     */
    _put(url, params, options = {}) {
        // Serialize locale & country after URL 
        let urlOptions = this._serialize({
            locale: this._shortLocale,
            country: this._market
        });
        url = url + (url.includes('?') ? `&${urlOptions}` : `?${urlOptions}`);

        let requestConfig = { ...options };
        requestConfig.validateStatus = function(status) {
            // Allow various status as 'valid'
            return (status >= 200 && status < 300) || status === 429;
        };
        return this._axios.put(url, params, requestConfig)
            .then(function(response) {
                const result = response.data;
                if (response.status === 200) {
                    return result;
                } else {
                    return result;
                }
            }).catch(function(error) {
                console.log('API Error: ', error);
                throw error;
            });
    }

    /**
     * Simple object serialization for query string requests s
     * @param {object} obj 
     */
    _serialize(obj) {
        let serialized = [];
        const mKeys = Object.keys(obj);
        for (const key of mKeys) {
            serialized.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
        return serialized.join('&');
    }

    /**
     * Store content into localStorage cache 
     * @param {string} key cache key
     * @param {object} data cache content 
     * @param {number} duration validity of cache in seconds. Defaults to one hour.
     */
    storeToCache(key, data, duration = 60 * 60) {
        if (localStorage) {
            let item = {
                data: data,
                appVersion: Config.getAppVersion(),
                // One hour timestamp validity
                timestamp: Date.now() + (1000 * duration)
            };
            localStorage.setItem(key, JSON.stringify(item));
        }
    }

    /**
     * Restore content from localStorage cache
     * @param {string} key 
     */
    restoreFromCache(key) {
        let rItem = null;
        if (localStorage) {
            let item = localStorage.getItem(key);
            if (item) {
                item = JSON.parse(item);
                if (item.timestamp > Date.now() && item.appVersion === Config.getAppVersion()) {
                    rItem = item.data;
                }
            }
        }
        return rItem;
    }

    /**
     * Create a cache key for storage
     * @param {string} url 
     * @param {*} params 
     */
    createCacheKey(url, params) {
        return 'cache_' + md5(url + JSON.stringify(params));
    }
}

const API = new _API();
export default API;
