import Vue from 'vue';
import API from '@/api.js';
import Cookies from '@/utils/Cookies.js';
import VueI18n from 'vue-i18n';
import I18nServiceError from '@/services/I18nServiceError';
import { UNSUPPORTED_LOCALE, UNSUPPORTED_MARKET } from '@/services/I18nServiceErrorCodes';

/**
 * I18n Service
 * Internationalization management
 * Store and define markets and associated locales
 */
class _I18nService {
    /**
     * 
     */
    constructor() {
        Vue.use(VueI18n);
        // Default configuration
        this.conf = {
            defaultLocale: 'fr'
        };
        this.currentMarket = '';
        // VueI18n instance 
        this._vueI18n = new VueI18n({
            locale: this.conf.defaultLocale,
            fallbackLocale: this.conf.defaultLocale,
            silentTranslationWarn: true
        });
    }

    /**
     * Initialization 
     * @param {Object} conf i18n configuration object with default & supported locales 
     */
    init(conf) {
        this.conf = { ...this.conf, ...conf };
    }

    /**
     * Define a new market. 
     * Will check stored locale: 
     * - if compatible with market, OK all fine, we can set this locale 
     * - else, set the default locale for this market.
     * @param {string} market the two letters market, for example 'fr'
     * @param {string} locale the two letters locale, for example 'en'
     * @return {Promise} empty promise, returned when locale is loaded
     */
    setMarket(market, locale = null) {
        if (this.getSupportedMarkets().includes(market)) {
            this.currentMarket = market;
            API.setMarket(market);
            const currentLocale = this.getStoredLocale();
            const marketConfig = this._getMarketConfig(market);
            if (locale !== null && marketConfig.availableLocales.includes(locale)) {
                // The requested locale is supported, we can go on and load this locale
                return this.setLocale(locale);
            } else if (locale !== null && !marketConfig.availableLocales.includes(locale)) {
                // The requested locale is not supported: reject promise with custom error code.
                return Promise.reject(new I18nServiceError(
                    `locale '${locale}' not supported by market '${market}'`,
                    UNSUPPORTED_LOCALE
                ));
            } else if (marketConfig.availableLocales.includes(currentLocale)) {
                // The current locale is supported by this market, we can go on and load this locale
                return this.setLocale(currentLocale);
            } else {
                // Locale is null or not supported: switch to the default locale registered for this market
                return this.setLocale(marketConfig.defaultLocale);
            }
        } else {
            // Unsupported market: reject promise with custom error code.
            return Promise.reject(new I18nServiceError(
                `market '${market}' does not exist.`,
                UNSUPPORTED_MARKET
            ));
        }
    }

    /**
     * Set new locale
     * @param {string} locale new locale string, for example 'fr_FR'
     */
    setLocale(locale) {
        API.setLocale(locale);
        return API.getI18n()
            .then((result) => {
                let messages = result && result.fo ? result.fo : {};
                this._vueI18n.locale = locale;
                this._vueI18n.mergeLocaleMessage(locale, messages);
                this._storeLocale(locale);
                this._setHTMLLocale(locale);
            })
            .catch((error) => {
                console.warn('I18nService: error loading i18n');
            });
    }

    /**
     * Return VueI18n instance 
     */
    getVueI18n() {
        return this._vueI18n;
    }

    /**
     * Get the current market
     * @param {Boolean} acceptFallback if true, will return the default market if current market is not supported
     * @returns {String} current market string, for example 'fr'
     */
    getCurrentMarket(acceptFallback = true) {
        return this.currentMarket || (acceptFallback ? this.getDefaultMarket() : null);
    }

    /**
     * Get the default market. 
     * Will be used to detect browser's default language / market. 
     * @return {string} example: 'fr'
     */
    getDefaultMarket() {
        // Consider browser's locale as a potential default market: detect browser language
        // If the language is like 'fr-CA', will try to get the 'CA' part. 
        let browserLang = navigator.language.slice(3, 5) || navigator.language.slice(0, 2);
        browserLang = browserLang.toLowerCase();
        // Check if browser language is a supported market
        const marketConfig = this._getMarketConfig(browserLang);
        if (marketConfig) {
            return browserLang;
        }
        return this.conf.defaultMarket;
    }

    /**
     * Return the list of supported markets, as flattened array. 
     * @returns {Array} example: ['fr', 'it']
     */
    getSupportedMarkets() {
        return this.conf.supportedMarkets.map((market) => {
            return market.code.toLowerCase();
        });
    }

    /**
     * 
     */
    getCurrentLocale() {
        return this._vueI18n.locale;
    }

    /**
     * 
     */
    getDefaultLocale() {
        return this.conf.defaultLocale;
    }

    /**
     * Get the current locale direction
     * @returns {string} 'rtl' | 'ltr' the current locale direction
     */
    currentLocaleDirection() {
        if (this.getCurrentLocale() === 'ar') {
            return 'rtl';
        }
        return 'ltr';
    }

    /**
     * Get supported locales for given market
     * @param {String} market the two letters market, for example 'fr'
     * @return {Array} array of supported locales, for example ['fr_FR', 'it_IT']
     */
    getMarketAvailableLocales(market = null) {
        const pMarket = (market === null) ? this.conf.defaultMarket : market;
        const marketConfig = this._getMarketConfig(pMarket);
        return marketConfig?.availableLocales || [];
    }

    /**
     * Get supported locales for current market
     * @returns {Array} array of supported locales, for example ['fr_FR', 'it_IT']
     */
    getCurrentMarketAvailableLocales() {
        return this.getMarketAvailableLocales(this.currentMarket);
    }

    /**
     * Get default locale for given market
     * @param {String} market the two letters market, for example 'fr' 
     * @returns {String} default locale, for example 'fr_FR'
     */
    getMarketDefaultLocale(market) {
        const marketConfig = this._getMarketConfig(market);
        return marketConfig?.defaultLocale || null;
    }

    /**
     * Get cookie-stored locale value
     */
    getStoredLocale() {
        let storedLocale = Cookies.getCookieValue('rdv_locale');
        return storedLocale;
    }

    /**
     * Return the current market + locale code, 
     * for example 'it_FR' where 'it' is the locale and 'FR' is the market.
     * Fallback to default values if market or locale are not set.
     * @returns {String} current market + locale code, for example 'it_FR'
     */
    getCurrentMarketLocaleCode() {
        const market = this.getCurrentMarket() || this.getDefaultMarket();
        const locale = this.getCurrentLocale() || this.getDefaultLocale();
        let marketLocaleCode = `${locale}_${(market.toUpperCase())}`;
        return marketLocaleCode;
    }

    /**
     * Store locale value in cookie
     * @param {String} locale the locale value to be stored
     */
    _storeLocale(locale) {
        Cookies.setCookieValue('rdv_locale', locale, 30);
    }

    /**
     * Register locale in HTML element
     * @param {string} locale the locale value 
     */
    _setHTMLLocale(locale) {
        document.documentElement.setAttribute('lang', locale);
    }

    /**
     * 
     * @param {String} market 
     */
    _getMarketConfig(market) {
        return this.conf.supportedMarkets.find((m) => m.code.toLowerCase() === market);
    }
}

const I18nService = new _I18nService();
export default I18nService;
