<template>
    <div class="card_wrapper" :class="odd ? 'odd' : ''">
        <div class="container">
            <div class="card card_service d_flex fd_sm_column p_rel">
                <div class="card_img set_ratio col_md_6">
                    <custom-image
                        v-if="active"
                        :data="service.mainImage"
                        @image-loaded="onImageLoaded"
                    ></custom-image>
                </div>
                <div class="card_content d_flex col_md_6 p_rel">
                    <div class="inner d_md_flex j_center fd_column full_w">
                        <div class="d_flex j_space_btw a_center">
                            <p class="t_uppercase mb_0 hn_style text-11">
                                {{ serviceTypeTitle }}
                            </p>
                            <p class="duration mb-0 hn_style text-11 d_flex a_center"><img src="@/assets/clock.svg" alt="">{{ duration }}</p>
                        </div>
                        <div class="text-xs-sm-center">
                            <h3 class="text-20 text-md-30 mt-md-0 mb-4">{{ service.name }}</h3>

                            <transition name="component-fade" mode="out-in" v-on:enter="onDeployTransitionEnter">
                                <div v-if="shouldCutContent && !displayMore" key="cut">
                                    <p v-html="description"></p>
                                    <p><button class="btn_bb_black text-10 mb-3" @click="onDisplayMoreClick">{{ $t('common-learn-more') }}</button></p>
                                </div>

                                <div v-if="!shouldCutContent || (shouldCutContent && displayMore)" key="uncut">
                                    <p v-html="description"></p>
                                    <p v-if="isPaidService && !hasSubServicesOptions" class="hn_style pb-1 pb-md-5">{{ service.price }} €</p>

                                    <!-- subservices without options -->
                                    <div class="mb-4" v-if="hasSubServices && !hasSubServicesOptions">
                                        <div class="mb-4" v-for="subservice in service.subServices" :key="subservice.id">
                                            <p class="mb-2"><span class="hn_style fw_400">{{ subservice.name }} :</span> {{ subservice.description }}</p>
                                            <router-link
                                                :to="{
                                                    name: isPaidService ?
                                                        (subServiceHasOptions(subservice) ? RouteNames.BOOK_SUBSERVICE_OPTIONS : RouteNames.BOOK_PAID)
                                                        : RouteNames.BOOK,
                                                    params: routeParams,
                                                    query: {
                                                        subservice: subservice.id
                                                    }
                                                }"
                                                v-html="$t('common-book-an-appointment')"
                                                class="btn_bb_black mb-3"
                                                :data-cy-button="service.digital ? 'type-virtual' : 'type-instore'"
                                                @click.native="trackButtonClick"
                                            >
                                            </router-link>
                                        </div>
                                    </div>

                                    <!-- subservices with options -->
                                    <div class="mb-4" v-else-if="hasSubServicesOptions">
                                        <router-link
                                            :to="{
                                                name: isPaidService ? RouteNames.BOOK_SUBSERVICE_OPTIONS : RouteNames.BOOK,
                                                params: routeParams,
                                                query: routeQuery
                                            }"
                                            v-html="$t('common-discover')"
                                            data-cy-button="type-paid-subservices-options"
                                            class="btn_bb_black"
                                            @click.native="trackButtonClick"
                                        >
                                        </router-link>
                                    </div>

                                    <!-- no subservices -->
                                    <div v-else class="mb-4">
                                        <router-link
                                            :to="{
                                                name: isPaidService ? RouteNames.BOOK_PAID : RouteNames.BOOK,
                                                params: routeParams,
                                                query: routeQuery
                                            }"
                                            v-html="$t('common-book-an-appointment')"
                                            :data-cy-button="
                                                isPaidService ?
                                                    (service.digital ? 'type-paid-virtual' : 'type-paid-instore') :
                                                    (service.digital ? 'type-virtual' : 'type-instore')"
                                            class="btn_bb_black"
                                            @click.native="trackButtonClick"
                                        >
                                        </router-link>
                                    </div>
                                    <p v-if="service.recapDescription" v-html="service.recapDescription"></p>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tools from '@/utils/tools';
import RouteNames from '@/const/RouteNames';
import ServiceTypes from '@/const/ServiceTypes';
import CustomImage from '@/components/common/CustomImage.vue';

export default {
    name: 'Service',
    data: () => ({
        RouteNames,
        ServiceTypes,
        displayMore: false,
        // Arbitrary index to cut on 'description' if no break provided.
        arbitraryCutLimit: 120
    }),
    props: {
        active: {
            type: Boolean,
            default: false
        },
        service: {
            type: Object, // Service
            default: () => {}
        },
        singleBoutiqueId: {
            type: String,
            default: null
        },
        singleBoutiqueSlug: {
            type: String,
            default: null
        },
        boutiquesOnly: {
            type: Boolean,
            default: false
        },
        wholesaleGroupSlug: {
            type: String,
            default: null
        },
        odd: {
            type: Boolean,
            default: false
        },
        // Following props for tracking purpose only.
        thematicCode: {
            type: String,
            default: null
        },
        serviceIndex: {
            type: Number,
            default: 0
        },
        serviceCount: {
            type: Number,
            default: 0
        }
    },
    computed: {
        /**
         * Build the route query string params.
         * Avoid having empty params if they are unnecessary.
         */
        routeQuery() {
            const query = {};
            if (this.wholesaleGroupSlug) {
                query.wholesale = this.wholesaleGroupSlug;
            }
            return query;
        },
        hasSubServices() {
            return Boolean(this.service?.subServices?.length > 0);
        },
        /**
         * Return true if any of the subservices has options
         */
        hasSubServicesOptions() {
            return this.service?.subServices?.some(
                (subservice) => subservice.options && subservice.options.category1?.options?.length > 0
            );
        },
        isPaidService() {
            return Boolean(this.service?.price > 0);
        },
        duration() {
            return `${this.service.clientDuration}’`;
        },
        serviceTypeTitle() {
            const title = [];
            if (this.service.type.store) {
                this.service.type.store.label
                    ? title.push(this.service.type.store.label)
                    : title.push(this.$t('common-appointment-instore'));
            }
            if (this.service.type.digital) {
                this.service.type.digital.label
                    ? title.push(this.service.type.digital.label)
                    : title.push(this.$t('common-appointment-virtual'));
            }
            return title.join(' / ');
        },
        /**
         * Should we cut content and display a "more information" button ?
         */
        shouldCutContent() {
            return !Tools.matchesWideDevice() &&
            (this.hasSubServices || this.service.longDescription) &&
            !this.hasSubServicesOptions;
        },
        /**
         * Cut the service description
         */
        description() {
            let content;
            if (this.shouldCutContent && !this.displayMore) {
                content = this.service.description;
            } else {
                content = this.service.longDescription || this.service.description;
            }
            return content;
        },
        /**
         * Common route params
         */
        routeParams() {
            return {
                serviceId: this.service.id,
                serviceType: this.service.digital ? ServiceTypes.DIGITAL : ServiceTypes.INSTORE,
                boutiqueId: this.singleBoutiqueId
                    ? this.singleBoutiqueId
                    : this.boutiquesOnly
                        ? 'boutiques'
                        : null,
                boutiqueSlug: this.singleBoutiqueSlug || null
            };
        }
    },
    components: {
        CustomImage
    },
    methods: {
        onDisplayMoreClick() {
            this.displayMore = true;
        },
        /**
         * Trigger the 'resize' event only when transition is entered, 
         * for correctly setting the content height
         */
        onDeployTransitionEnter() {
            this.$emit('resize');
        },
        /**
         * Trigger a 'resize' event when image is loaded,
         * for correctly setting the content height
         */
        onImageLoaded() {
            this.$emit('resize');
        },
        /**
         * Check whether the subservice has available options
         * @param {SubService} subservice 
         */
        subServiceHasOptions(subservice) {
            return !!(subservice.options &&
                subservice.options.category1 &&
                subservice.options.category1.options &&
                subservice.options.category1.options.length &&
                subservice.options.priceCombinations &&
                subservice.options.priceCombinations.length);
        },
        /**
         *
         */
        trackButtonClick() {
            this.$Tracker.event({
                eventcategory: 'rdv chanel_cta',
                eventlabel: `${this.serviceIndex + 1}/${this.serviceCount}_${this.service.id}`,
                eventaction: `book_${this.thematicCode}`
            });
        }
    }
};
</script>
