var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import ServiceTypes from '@/const/ServiceTypes';
import { findServiceOptionsPrice } from '@/services/helpers/ServiceModelHelpers';
/**
 * Helper method.
 * Group provided services list by thematics
 * @param services array of services
 * @returns array of arrays of services
 *
 * Sample return:
 *    [
 *         [ // Thema 1
 *             { service },
 *             { service }
 *         ],
 *         [ // Thema 2
 *             { service },
 *             { service }
 *         ]
 *    ]
 */
export function groupServicesByThematics(services) {
    // Get rid of services without thematics
    var filteredServices = services.filter(function (service) { return service.thematic; });
    // Create a new map with an empty array as single entry for each thematic 
    var map = new Map(filteredServices.map(function (obj) {
        return [(obj.thematic ? obj.thematic.code : ''), []];
    }));
    // Push object data in each map thematic key 
    Array.from(filteredServices, function (obj) {
        var thema = obj.thematic ? obj.thematic.code : '';
        var themaArray = map.get(thema);
        if (themaArray) {
            themaArray.push(obj);
            map.set(thema, themaArray);
        }
    });
    // Transform the map as array 
    var thematicServices = Array.from(map.values());
    return thematicServices;
}
/**
 * Get services
 * @param state vuex Service state
 * @returns all services
 */
export var getServices = function (state) {
    return state.services;
};
/**
 * Get unhidden services only
 * @param state vuex Service state
 * @returns array of unhidden services
 */
export var getUnhiddenServices = function (state) {
    return state.services.filter(function (service) {
        return !service.hidden;
    });
};
/**
 * Get the list of unique services thematics keys
 * @param state vuex Service state
 * @returns list of unique thematics keys
 */
export var getThematicKeys = function (state) {
    var thematicSet = new Set(state.services.map(function (service) {
        return service.thematic ? service.thematic.code : '';
    }));
    return Array.from(thematicSet.values());
};
/**
 * Get the list of unique services thematics
 * @param state vuex Service state
 * @returns list of unique thematics
 */
export var getThematics = function (state) {
    // Get rid of services without thematics
    var filteredServices = state.services.filter(function (service) { return service.thematic; });
    var thematicSet = new Set(filteredServices.map(function (service) {
        return service.thematic;
    }));
    var thematicArray = Array.from(thematicSet.values());
    // Extract unique thematics from the set, keeping the thematics order
    return thematicArray
        .filter(function (currentThematic, index, arr) { return arr.findIndex(function (thematic) { return thematic.code === currentThematic.code; }) === index; });
};
/**
 * Get services list grouped by thematics
 * @param state vuex Service state
 * @returns array of arrays of services
 */
export var getThematicServices = function (state) {
    return groupServicesByThematics(state.services);
};
/**
 * Get services list filtered according to thematicFilter and serviceTypeFilter.
 * @param state vuex Service state
 * @returns array of services
 */
export var getFilteredServices = function (state) {
    var filteredServices = __spreadArray([], state.services, true);
    filteredServices = filteredServices.filter(function (service) {
        var _a;
        var qualified = true;
        if (state.thematicFilter) {
            qualified = qualified && (((_a = service.thematic) === null || _a === void 0 ? void 0 : _a.code) === state.thematicFilter);
        }
        if (state.serviceTypeFilter) {
            var shouldBeDigital = state.serviceTypeFilter === ServiceTypes.DIGITAL;
            qualified = qualified && (service.digital === shouldBeDigital);
        }
        return qualified;
    });
    return filteredServices;
};
/**
 * Get thematic-grouped services list filtered according to thematicFilter and serviceTypeFilter.
 * Preserve the 'getThematicServices' return structure for usage consistency.
 * @param state vuex Service state
 * @returns array of arrays of services
 */
export var getFilteredThematicServices = function (state) {
    var filteredServices = getFilteredServices(state);
    return groupServicesByThematics(filteredServices);
};
/**
 * Get unhidden-only thematic-grouped services list filtered according to thematicFilter and serviceTypeFilter.
 * Preserve the 'getThematicServices' return structure for usage consistency.
 * @param state vuex Service state
 * @returns array of arrays of services
 */
export var getUnhiddenFilteredThematicServices = function (state) {
    var filteredServices = getFilteredServices(state);
    filteredServices = filteredServices.filter(function (service) {
        return !service.hidden;
    });
    return groupServicesByThematics(filteredServices);
};
/**
 * Get single service by service ID
 * @param state vuex Service state
 * @returns matching service
 */
export var getService = function (state) { return function (serviceId) {
    return state.services.find(function (service) { return service.id === serviceId; });
}; };
/**
 * Get the provided service price, according to provided subservice and options.
 * Falls back to the lowest price if no matching price is found.
 * @param service
 * @param subServiceId
 * @param category1OptionId
 * @param category2OptionId
 * @returns price matching provided parameters, or lowest price if no match is found
 */
export var getServiceOptionsPrice = function (state) {
    return function (service, subServiceId, category1OptionId, category2OptionId) {
        return findServiceOptionsPrice(service, subServiceId, category1OptionId, category2OptionId);
    };
};
/**
 * Get the provided service available types, normalized way
 * @param service
 * @returns array of available service type objects
 */
export var getNormalizedServiceTypes = function (state) { return function (service) {
    var sOwnTypes;
    if (service.type) {
        sOwnTypes = __assign({}, service.type);
    }
    else {
        sOwnTypes = {};
        if (service.digital) {
            sOwnTypes.digital = {
                active: true,
                code: ServiceTypes.DIGITAL,
                label: '',
                description: ''
            };
        }
        if (service.store) {
            sOwnTypes.store = {
                active: true,
                code: ServiceTypes.INSTORE,
                label: service.storeLabel,
                description: service.description
            };
        }
        if (service.digitalInStore) {
            sOwnTypes.digitalInStore = {
                active: true,
                code: ServiceTypes.DIGITALINSTORE,
                label: '',
                description: service.digitalInStoreDescription
            };
        }
    }
    return sOwnTypes;
}; };
