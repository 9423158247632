<template>
    <div
        class="d_md_flex fd_column col_md_4"
        v-if="service"
        v-cy-helper="{ id:`service_${service.id}` }"
    >
        <custom-image
            v-if="service.mainImage"
            :data="service.mainImage"
            class="wrapper full has-img-cover"
        ></custom-image>
        <div class="card-light d_md_flex fd_column j_space_btw full_h mb-xs-4">
            <div>
                <h3 class="text-14 mt-0 c-black-light">{{ service.name }}</h3>
                <div :class="hasMultipleTypes ? 'ml-2' : ''">
                    <template v-if="!hasSubServices">
                        <div v-for="sType, sTypeIndex in service.type" class="mb-5" :key="sTypeIndex">
                            <ul v-if="hasMultipleTypes" class="fw_300 pt-1">
                                <li class="custom_checkbox custom-checkbox-alternate pl-4">
                                    <label
                                        class="p_rel text-12"
                                        v-cy-helper="{ radio:`type_${sType.code}` }"
                                    >
                                        <input
                                            :name="`service_${service.id}`"
                                            type="radio"
                                            :value="sType.code"
                                            v-model="serviceTypeModel"
                                        />
                                        <span class="check"></span>
                                        <span class="d_block text-10 c_offblack hn_style line-16 mb-3">
                                            {{ sType.label || (sType.code === ServiceTypes.INSTORE ? $t('common-appointment-boutique') : $t('common-appointment-virtual')) }}
                                        </span>
                                        <p v-if="sType.description">{{ sType.description }}</p>
                                    </label>
                                </li>
                            </ul>
                            <p v-else-if="sType.description">{{ sType.description }}</p>
                        </div>
                    </template>

                    <template v-else>
                        <p class="text-14 c_offblack pb-5 mb-0">{{ service.description }}</p>
                        <div class="pt-5 mb-5 full_sep_light">
                            <p class="text-14 fw_400 c-black-light" v-html="$t('fsh-subservice-select')"></p>
                            <ul class="fw_300 pt-1">
                                <li
                                    v-for="subservice in service.subServices"
                                    :key="subservice.id"
                                    class="custom_checkbox custom-checkbox-alternate pl-4"
                                >
                                    <label class="p_rel text-12">
                                        <input
                                            :name="`subservice_${service.id}`"
                                            type="radio"
                                            :value="subservice.id"
                                            v-model="subserviceModel"
                                        />
                                        <span class="check"></span>
                                        <span class="text-14 c_offblack">{{ subservice.name }}</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </template>
                </div>
            </div>

            <div class="d_flex j_center">
                <router-link
                    :to="{
                        name: RouteNames.BOOK,
                        params: {
                            serviceId: service.id,
                            serviceType: serviceTypeModel || ServiceTypes.INSTORE,
                            boutiqueId: singleBoutiqueId || null,
                            boutiqueSlug: singleBoutiqueSlug || null
                        },
                        query: routeQuery
                    }"
                    class="btn btn_black hn_style fw_300 full_w"
                    v-on:click.native="trackButtonClick"
                    v-html="$t('common-book-an-appointment')"
                    v-cy-helper="{ button:'book' }"
                >
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import CyHelper from '@/directives/CyHelper';
import RouteNames from '@/const/RouteNames';
import ServiceTypes from '@/const/ServiceTypes';
import CustomImage from '@/components/common/CustomImage.vue';

export default {
    directives: { CyHelper },
    data: () => ({
        RouteNames,
        ServiceTypes,
        subserviceModel: null,
        serviceTypeModel: ServiceTypes.INSTORE
    }),
    props: {
        service: {
            type: Object, // Service
            default: null
        },
        singleBoutiqueId: {
            type: String,
            default: null
        },
        singleBoutiqueSlug: {
            type: String,
            default: null
        }
    },
    computed: {
        hasSubServices() {
            return Boolean(this.service?.subServices?.length > 0);
        },
        /**
         * Check if the service has multiple types, for example 'store', 'virtual', etc.
         */
        hasMultipleTypes() {
            return this.service?.type && Object.keys(this.service.type).length > 1;
        },
        /**
         * Build the route query string params.
         * Avoid having empty params if they are unnecessary.
         */
        routeQuery() {
            return this.subserviceModel ? { subservice: this.subserviceModel } : {};
        }
    },
    watch: {
        service(newService) {
            this.setDefaultSubService();
        }
    },
    components: {
        CustomImage
    },
    created() {
        this.setDefaultSubservice();
    },
    methods: {
        /**
         * Define default subservice, if existing subServices,
         * to avoid the possibility of clicking the link without selecting one of the subServices.
         */
        setDefaultSubservice() {
            if (this.service.subServices && this.service.subServices.length > 0) {
                this.subserviceModel = this.service.subServices[0].id;
            } else {
                this.subserviceModel = null;
            }
        },
        /**
         * 
         */
        trackButtonClick() {
            const event = {
                eventcategory: 'appointment booking',
                eventaction: this.service.id
            };
            const subservice = this.subserviceModel
                ? this.service.subServices.find((sub) => sub.id === this.subserviceModel) : null;
            if (subservice) {
                event.eventlabel = subservice.code;
            }
            this.$Tracker.event(event);
        }
    }
};
</script>
