/**
 * Custom error type for I18nService, 
 * include some status code.
 */
class I18nServiceError extends Error {
    /**
     * Constructor
     * @param {string} message explicit message
     * @param {string} statusCode status code from I18nServiceErrorCodes
     */
    constructor(message, statusCode) {
        super(message);
        this.statusCode = statusCode;
    }
}

export default I18nServiceError;
