import Vue from 'vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import App from './App.vue';
import Maintenance from './Maintenance.vue';
import Router from './router';
import Config from './utils/config.js';
import API from '@/api.js';
import Tracker from '@/utils/Tracker.js';
import I18nService from '@/services/I18nService.js';
import Meta from 'vue-meta';
import store from '@/store/index.ts';
import { divisionFromCode, divisionFromApiCode } from '@/utils/DivisionsHelper';
import '@/directives/Observe.js';
import '@/utils/EventBubbling.js';

/**
 * CSS Entry Point
*/
import './scss/main.scss';

console.log(`Version ${process.env.VERSION}, built on ${process.env.BUILD_DATE}`);
// The STANDALONE and RESOURCES_URL const are statically defined in HTML file
// eslint-disable-next-line no-undef
const isStandalone = STANDALONE === true;
console.log(isStandalone ? 'Standalone Run' : 'Connector Run');
// eslint-disable-next-line no-undef
const resourcesUrl = RESOURCES_URL;
// Redefine public path on runtime, for non-standalone runs only
if (!isStandalone) {
    // eslint-disable-next-line no-undef, camelcase
    __webpack_public_path__ = `${resourcesUrl}/`;
}

/**
 * Service Worker unregistration
 */
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for (let registration of registrations) {
            registration.unregister();
        }
    }).catch(function(err) {
        console.log('Service Worker registration failed: ', err);
    });
}

/**
 * Configuration
 * Loaded first, before app initialization
 */
API.getConfiguration(isStandalone ? '/config.json' : `${resourcesUrl}/config.json`)
    .then(function(result) {
        Config.setConfig(result);
        API.init();
        // As API is ready, can now call i18n conf from the API
        API.getI18nConfiguration()
            .then((result) => {
                Config.setSupportedMarkets(result);
                // All OK, can launch Vue app
                initVueApp();
            })
            .catch((error) => {
                console.warn('Error while getting i18n configuration. Will switch to fallback mode.', error);
                initMaintenanceVueApp();
            });
    })
    .catch(function(error) {
        console.warn(error.name + ': ' + error.message);
    });

/**
 * DayJS configuration
 */
dayjs.extend(utc);

/**
 * i18n
 * Site localization 
 */
Vue.prototype.$I18nService = I18nService;

/**
 * Tracking
 * Add track function as Vue prototypes. 
 * Tracker defaults will be defined once market and division are known, in App.vue.
 */
Vue.prototype.$Tracker = Tracker;

/**
 * Meta
 */
Vue.use(Meta, {
    keyName: 'metaInfo'
});

/**
 * Application Entry Point
 */
Vue.config.productionTip = false;
function initVueApp() {
    // Various essential initializations 
    Config.setAppVersion(process.env.VERSION);
    // I18nService is not initialized here anymore, as i18n configuration will depend on division. 
    // I18nService initialization has been moved to App.vue.
    // Code below is kept for reference.
    //
    // const i18nConfig = Config.getOption('i18n');
    // I18nService.init(i18nConfig);
    // Router.setDefaultMarket(I18nService.getDefaultMarket());
    //
    const divisionsCodes = Config.getSupportedDivisions();
    const defaultDivisionCode = Config.getDefaultDivision();
    const divisionsSeoNames = divisionsCodes.map((code) => divisionFromCode(code)?.seoName);
    // Register supported divisions by markets towards the router
    const divisionsByMarkets = Config.getSupportedDivisionsByMarkets();
    // Convert divisions API codes to router's usable SEO names 
    let routerDivisionsByMarkets = divisionsByMarkets.map((market) => {
        return {
            code: String(market.code).toLowerCase(),
            divisions: market.divisions.map(div => divisionFromApiCode(div)?.seoName)
        };
    });
    Router.setSupportedDivisionsByMarkets(routerDivisionsByMarkets);
    Router.setSupportedDivisions(divisionsSeoNames);
    Router.setDefaultDivision(divisionFromCode(defaultDivisionCode)?.seoName);
    // Standalone: router use history mode, base set on BASE_URM
    // Non-standalone: router use hash mode, base set on current URL pathname
    Router.init(isStandalone, isStandalone ? process.env.BASE_URL : window.location.pathname);

    // Router errors catch-all, to prevent routing issues:
    // Redirect to default market/division
    const routerInstance = Router.getRouter();
    routerInstance.onError((error) => {
        // Force redirect to default country & division
        routerInstance.push({
            name: '',
            params: {
                market: I18nService.getDefaultMarket(),
                division: divisionFromCode(Config.getDefaultDivision())?.seoName
            }
        });
    });

    new Vue({
        router: Router.getRouter(),
        i18n: I18nService.getVueI18n(),
        store,
        render: h => h(App)
    }).$mount('#app');
}

/**
 * Maintenance mode:
 * Minimal Vue application, without any dependancy.
 */
function initMaintenanceVueApp() {
    new Vue({
        render: h => h(Maintenance)
    }).$mount('#app');
}
