/**
 * Cypress Test Helper directive
 * Easing access to DOM element during Cypress tests,
 * while maintaining clean production build.
 *
 * Usage:
 * <div v-cy-helper="{ myparam:'my Value'}">...</div>
 *
 * Each param will be used as a custom `data-cy-myparam` attribute,
 * allowing clean access to DOM elements by Cypress test runner.
 *
 * EXAMPLE:
 * page.vue:
 *      script:
 *         import CyHelper from '~/directives/cy-helper';
 *         // ...
 *         directives : {
 *             CyHelper
 *         }
 *      template:
 *          <div v-cy-helper="{ status:'order' }"></div>
 *
 * render :
 *          <div data-cy-status="order"></div>
 *
 *
 * NOTE:
 *      This directive should be activated only when on 'test' or 'development' modes.
 *      However, CI/CD testing runs in 'production' mode and thus requires the data-cy attributes.
 *
 */

export default {
    inserted(el, binding) {
        // if (['test', 'testing', 'development'].includes(process.env.NODE_ENV)) {
        Object.keys(binding.value).forEach((value) => {
            el.setAttribute(`data-cy-${value}`, binding.value[value]);
        });
    }
};
