import Vue from 'vue';
import Router from 'vue-router';
import RouteNames from '@/const/RouteNames';
import BookUpdateScopes from '@/const/BookUpdateScopes';
import Home from './views/Home.vue';

/**
 * Vue Router wrapper
 */
class _Router {
    /**
     * 
     */
    constructor() {
        Vue.use(Router);
        this.defaultMarket = 'fr';
        this.defaultDivision = 'beauty';
        this.divisions = ['beauty'];
        this.divisionsByMarkets = null;
        this.routes = [
            {
                path: '/',
                name: RouteNames.HOME,
                component: Home
            },
            {
                // Scope to CHANEL Boutiques only
                path: '/boutiques',
                name: RouteNames.HOME_BOUTIQUES,
                component: Home
            },
            {
                // Scope to specific CHANEL Boutique
                path: '/boutique/:boutique([a-zA-Z0-9\-]+)',
                name: RouteNames.HOME_BOUTIQUE,
                component: Home
            },
            {
                // Scope to wholesale boutiques groups
                path: '/wh/:wholesaleGroupSlug(marionnaud|nocibe|sephora)', // ([a-zA-Z0-9\-]+)',
                name: RouteNames.HOME_WHOLESALE_GROUP,
                component: Home
            },
            
            {
                path: '/servicelist',
                name: RouteNames.SERVICE_LIST,
                redirect: { name: RouteNames.HOME }
            },
            {
                path: '/servicedetail/:serviceId',
                name: RouteNames.SERVICE_DETAILS,
                component: () => import(/* webpackChunkName: "servicedetail" */ './views/ServiceDetail.vue')
            },
            {
                // SubService options selection before continuing with booking module
                path: '/book/options/:serviceId/:serviceType(digital|instore|digitalinstore)/:boutiqueSlug(boutiques|[a-zA-Z0-9\-]+)?/:boutiqueId([A-Z0-9]+)?',
                name: RouteNames.BOOK_SUBSERVICE_OPTIONS,
                component: () => import(/* webpackChunkName: "booksubserviceoptions" */ './views/BookSubServiceOptions.vue')
            },
            {
                // Date&POS booking module
                path: '/book/:serviceId/:serviceType(digital|instore|digitalinstore)/:boutiqueSlug(boutiques|[a-zA-Z0-9\-]+)?/:boutiqueId([A-Z0-9]+)?',
                name: RouteNames.BOOK,
                component: () => import(/* webpackChunkName: "book" */ './views/Book.vue')
            },
            {
                // Date&POS booking form
                path: '/book/contact-information',
                name: RouteNames.BOOK_FORM,
                component: () => import(/* webpackChunkName: "bookform" */ './views/BookForm.vue')
            },
            {
                // Date&POS booking confirmation
                path: '/book/confirmation',
                name: RouteNames.BOOK_CONFIRMATION,
                component: () => import(/* webpackChunkName: "bookconfirmation" */ './views/BookConfirmation.vue')
            },
            {
                // Booking management: form
                path: '/book/manage/:bookingId([a-zA-Z0-9]+)?',
                name: RouteNames.BOOK_MANAGE,
                component: () => import(/* webpackChunkName: "bookmanage" */ './views/BookManage.vue')
            },
            {
                // Booking management: update
                path: `/book/update/:updateScope(${BookUpdateScopes.DATE}|${BookUpdateScopes.POINT_OF_SALE})`,
                name: RouteNames.BOOK_UPDATE,
                component: () => import(/* webpackChunkName: "book" */ './views/Book.vue')
            },
            {
                // Booking management: update confirmation
                path: '/book/update/confirmation',
                name: RouteNames.BOOK_UPDATE_CONFIRMATION,
                component: () => import(/* webpackChunkName: "bookconfirmation" */ './views/BookConfirmation.vue')
            },
            {
                path: '/bookingconfirm/:bookingId([a-zA-Z0-9]+)?',
                name: RouteNames.BOOK_CONFIRM,
                component: () => import(/* webpackChunkName: "bookingconfirm" */ './views/BookingConfirm.vue')
            },
            {
                // Classic booking module
                // Set '/boutiques' to scope availabilities to CHANEL Boutiques only. 
                path: '/bookservice/:serviceId/:serviceType(digital|instore)/:boutiqueSlug(boutiques|[a-zA-Z0-9\-]+)?/:boutiqueId([A-Z0-9]+)?',
                name: RouteNames.BOOK_SERVICE,
                component: () => import(/* webpackChunkName: "bookservice" */ './views/Booking.vue')
            },
            {
                // Paid Services booking module
                path: '/bookpaidservice/:serviceId/:serviceType(digital|instore)/:boutiqueSlug(boutiques|[a-zA-Z0-9\-]+)?/:boutiqueId([A-Z0-9]+)?',
                name: RouteNames.BOOK_PAID,
                component: () => import(/* webpackChunkName: "bookpaid" */ './views/BookPaid.vue')
            },
            {
                // Paid Services booking form
                path: '/bookpaidservice/contact-information',
                name: RouteNames.BOOK_PAID_FORM,
                component: () => import(/* webpackChunkName: "bookform" */ './views/BookForm.vue')
            },
            {
                // Paid Services booking confirmation
                path: '/bookpaidservice/confirmation',
                name: RouteNames.BOOK_PAID_CONFIRMATION,
                component: () => import(/* webpackChunkName: "bookconfirmation" */ './views/BookPaidConfirmation.vue')
            },
            {
                path: '/bookingcancel/:bookingId([a-zA-Z0-9]+)?',
                name: RouteNames.BOOK_CANCEL,
                component: () => import(/* webpackChunkName: "bookingcancel" */ './views/BookingCancel.vue')
            },
            {
                // Retail version 
                path: '/r/bookingcancel/:bookingId([a-zA-Z0-9]+)?',
                name: RouteNames.RETAIL_BOOK_CANCEL,
                component: () => import(/* webpackChunkName: "bookingcancel" */ './views/BookingCancel.vue')
            },
            {
                path: '/contact',
                name: RouteNames.CONTACT,
                component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue')
            },
            {
                path: '/tryon/:loginKey([a-zA-Z0-9]+)?',
                name: RouteNames.TRYON,
                component: () => import(/* webpackChunkName: "tryon" */ './views/TryOn.vue')
            },
            {
                path: '/tryon-connected',
                name: RouteNames.TRYON_CONNECTED,
                component: () => import(/* webpackChunkName: "tryon_connected" */ './views/TryOnConnected.vue')
            },
            {
                path: '/tryon-closed',
                name: RouteNames.TRYON_CLOSED,
                component: () => import(/* webpackChunkName: "tryon_closed" */ './views/TryOnClosed.vue')
            },
            {
                path: '/voc/:loginKey([a-zA-Z0-9]+)',
                name: RouteNames.VOC,
                component: () => import(/* webpackChunkName: "voc" */ './views/VOC.vue')
            },
            {
                // Client presence set by Advisor, post-booking
                path: '/presence',
                name: RouteNames.PRESENCE,
                component: () => import(/* webpackChunkName: "presence" */ './views/PresenceConfirmation.vue')
            },
            {
                // Client presence confirmation, set by client pre-booking
                path: '/client-confirmation',
                name: RouteNames.CLIENT_ATTENDANCE,
                component: () => import(/* webpackChunkName: "client_attendance" */ './views/AttendanceConfirmation.vue')
            },
            {
                path: '/health',
                name: RouteNames.HEALTH,
                component: () => import(/* webpackChunkName: "faq" */ './views/Health.vue')
            },
            // {
            //     // Debug-only route.
            //     // Activate to generate mockup availabilites
            //     path: '/generator',
            //     name: 'generator',
            //     component: () => import(/* webpackChunkName: "faq" */ './views/Generator.vue')
            // },
            {
                path: '/*',
                name: RouteNames.LOCALIZED_404,
                component: () => import(/* webpackChunkName: "404" */ './views/404.vue')
            },
            {
                path: '/:market([a-z]{2})/(.*)',
                name: RouteNames.MISSING_DIVISION_404,
                component: () => import(/* webpackChunkName: "404" */ './views/404.vue')
            },
            {
                path: '*',
                name: RouteNames.GLOBAL_404,
                component: () => import(/* webpackChunkName: "404" */ './views/404.vue')
            }
        ];
    }

    /**
     * Router initialization:
     * - create Router instance 
     * - get i18n routes 
     * - redirects if locale not present in route 
     */
    init(useHistoryMode = true, base = process.env.BASE_URL) {
        // Create Router instance with localized routes 
        this.router = new Router({
            mode: useHistoryMode ? 'history' : 'hash',
            base,
            routes: this.getPrefixedRoutes(),
            scrollBehavior(to, from, savedPosition) {
                if ('scrollRestoration' in history) {
                    history.scrollRestoration = 'manual';
                }
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve(savedPosition || { x: 0, y: 0 });
                    }, (150));
                });
            }
        });
        // Navigation Guard on router: 
        // Make sure both 'market' and 'division' parameter are present.
        // If not, redirect to default division homepage.
        this.router.beforeEach((to, from, next) => {
            let market = to.params.market;
            let division = to.params.division;
            if (!market && !division) {
                // Redirect to defaults
                const fullPath = `/${this.defaultMarket}/${this.defaultDivision}`;
                next(fullPath);
            } else {
                // All OK: resolve without redirecting 
                next();
            }
        });
    }

    /**
     * Set default market, as a two-letters string. 
     * @param {String} market the two-letters default market, for example 'fr'
     */
    setDefaultMarket(market) {
        this.defaultMarket = market;
    }

    /**
     * Set supported divisions
     * @param {array} divisions array of supported divisions SEO names, for example ['beauty','mode']
     */
    setSupportedDivisions(divisions) {
        this.divisions = [...divisions];
    }

    /**
     * Set supported divisions by markets
     * @param {Array} divisionsByMarkets array of divisions SEO names by markets, 
     * for example [{ code: 'fr', divisions: ['mode', beauty']}, ...]
     */
    setSupportedDivisionsByMarkets(divisionsByMarkets) {
        this.divisionsByMarkets = divisionsByMarkets;
    }

    /**
     * Set default division
     * @param {string} division the division normalized english name, for example 'beauty'
     */
    setDefaultDivision(division) {
        this.defaultDivision = division;
    }

    /**
     * 
     */
    getPrefixedRoutes() {
        const divisions = this.divisions.join('|');
        return this.routes.map(route => {
            let prefixedRoute = { ...route };
            if (![RouteNames.GLOBAL_404, RouteNames.MISSING_DIVISION_404].includes(prefixedRoute.name)) {
                // Market prefix should match a 'fr' pattern. 
                // For all routes except 'home' and '404', market param is mandatory, to maintain value during navigation. 
                // - 'home' allows optionnal market param, for direct access and redirection to default or current market. 
                // - '404' should catch everything else, so no market in pattern
                // (see navigation guard)
                prefixedRoute.path = `/:market([a-z]{2})/:division(${divisions})` + (prefixedRoute.name === RouteNames.HOME ? '?' : '') + prefixedRoute.path;
            }
            return prefixedRoute;
        });
    }

    /**
     * 
     */
    getRouter() {
        return this.router;
    }
}

export default new _Router();
