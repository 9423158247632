<template>
    <div class="services_group p_rel" :class="odd ? 'odd' : ''" v-observe="{ enter:onEnter }">
        <div :class="`services_slider_${thematicCode}`">
            <cover
                :thematic="thematic"
                :odd="odd"
                @click="onCoverClick"
            />
            <service
                v-for="(service, index) in services"
                :active="entered"
                :key="service.id"
                :service="service"
                :singleBoutiqueId="singleBoutiqueId"
                :singleBoutiqueSlug="singleBoutiqueSlug"
                :boutiquesOnly="boutiquesOnly"
                :wholesaleGroupSlug="wholesaleGroupSlug"
                :odd="odd"
                :thematicCode="thematicCode"
                :serviceIndex="index"
                :serviceCount="services.length"
                @resize="onServiceResize"
            />
        </div>
        <div class="slider_arrows d_flex j_space_btw full_w" :class="`slider_arrows_${this.thematicCode}`">
            <button
                class="nbs arrow left no_btn_style"
                @click="onPreviousButtonClick"
                data-cy-button="slider_arrow_previous"
            >
                <img src="@/assets/light-arrow-prev.svg" alt="">
            </button>
            <button
                class="nbs arrow right no_btn_style"
                @click="onNextButtonClick"
                data-cy-button="slider_arrow_next"
            >
                <img src="@/assets/light-arrow-next.svg" alt="">
            </button>
        </div>
        <div class="slider_counter text_center mb-6">
            <p class="mb-0 hn_style text-12 c_black_light">{{ slideIndex }} / {{ slideCount - 1 }}</p></div>
    </div>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider';
import Cover from '@/components/templates/beauty/homebeauty/Cover.vue';
import Service from '@/components/templates/beauty/homebeauty/Service.vue';

const RIGHT = 'right';
const LEFT = 'left';

export default {
    name: 'ServicesGroup',
    data: () => ({
        slider: null,
        slideCount: 0,
        slideIndex: 0,
        entered: false,
        sliderButtonDirection: null
    }),
    props: {
        odd: {
            type: Boolean,
            default: false
        },
        thematic: {
            type: Object, // Thematic
            default: () => {}
        },
        services: {
            type: Array, // Array<Service>
            default: () => []
        },
        singleBoutiqueId: {
            type: String,
            default: null
        },
        singleBoutiqueSlug: {
            type: String,
            default: null
        },
        boutiquesOnly: {
            type: Boolean,
            default: false
        },
        wholesaleGroupSlug: {
            type: String,
            default: null
        }
    },
    computed: {
        thematicCode() {
            return this.thematic ? this.thematic.code : '';
        }
    },
    components: {
        Cover,
        Service
    },
    mounted() {
        // Let some time for the props to be available, 
        // before initialising the TNS slideshow
        if (!this.slider) {
            this.$nextTick(() => {
                this.createSlider();
            });
        }
    },
    beforeDestroy() {
        this.destroySlider();
    },
    methods: {
        /**
         * Intersection Observer handler
         */
        onEnter() {
            if (!this.entered) {
                this.entered = true;
            }
        },
        /**
         * 
         */
        createSlider() {
            this.slider = tns({
                container: `.services_slider_${this.thematicCode}`,
                nav: false,
                mouseDrag: true,
                controlsContainer: `.slider_arrows_${this.thematicCode}`,
                items: 1,
                loop: false,
                autoHeight: true,
                responsive: {
                    768: {
                        autoHeight: false
                    }
                },
                speed: 800
            });
            const sliderInfos = this.slider.getInfo();
            this.slideCount = sliderInfos.slideCount;
            // Register event to update slider index on change
            this.slider.events.on('indexChanged', this.onSliderIndexChanged);
        },
        /**
         * Properly destroy slider & remove event listeners
         */
        destroySlider() {
            if (this.slider) {
                if (this.slider.events) {
                    this.slider.events.off('indexChanged', this.onSliderIndexChanged);
                }
                this.slider.destroy();
            }
        },
        /**
         * Actualize slider index
         */
        onSliderIndexChanged(params) {
            this.slideCount = params.slideCount;
            this.slideIndex = params.index;

            // ----------
            // Tracking purpose only
            const label = this.slideIndex === 0 ? this.thematicCode : this.services[this.slideIndex - 1].id;
            const action = this.sliderButtonDirection
                ? `go ${this.sliderButtonDirection}_${this.thematicCode}`
                : `drag_${this.thematicCode}`;
            this.$Tracker.event({
                eventcategory: 'rdv chanel_carousel',
                eventlabel: `${this.slideIndex}/${this.slideCount - 1}_${label}`,
                eventaction: action
            });
            this.sliderButtonDirection = null;
        },
        /**
         * Clik on Cover handler: 
         * switch to second slide (first service)
         */
        onCoverClick() {
            if (this.slider) {
                this.slider.goTo(1);
            }
        },
        /**
         * Click on "know more" button on Service: 
         * react to content height deployment
         */
        onServiceResize() {
            if (this.slider) {
                this.$nextTick(() => {
                    this.slider.updateSliderHeight();
                });
            }
        },
        /**
         * Tracking purpose only
         */
        onPreviousButtonClick() {
            this.sliderButtonDirection = LEFT;
        },
        onNextButtonClick() {
            this.sliderButtonDirection = RIGHT;
        }
        // ----------
    }
};
</script>
