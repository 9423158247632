import * as getters from '@/store/booking/getters';
import * as actions from '@/store/booking/actions';
import mutations from '@/store/booking/mutations';
var state = function () { return ({
    availability: undefined,
    geolocatedAvailability: false,
    pointOfSaleResultList: [],
    selectedStandalonePointOfSale: undefined,
    selectedPointOfSale: undefined,
    selectedDate: undefined,
    selectedTime: undefined,
    selectedSlot: undefined,
    selectedService: undefined,
    selectedServiceType: undefined,
    selectedSubService: undefined,
    selectedSubServiceCategory1OptionId: undefined,
    selectedSubServiceCategory2OptionId: undefined,
    message: undefined,
    confirmation: undefined,
    currentBooking: undefined,
    currentBookingEmail: undefined
}); };
/**
 * Store and manage everything booking-related.
 */
export default {
    state: state,
    namespaced: true,
    getters: getters,
    mutations: mutations,
    actions: actions
};
