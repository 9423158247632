var _a;
import { SET_AVAILABILITY, SET_GEOLOCATED_AVAILABILITY, SET_POINT_OF_SALE_LIST, SET_GEOLOCATED_POINT_OF_SALE_LIST, SET_SELECTED_STANDALONE_POINT_OF_SALE, SET_SELECTED_POINT_OF_SALE, SET_SELECTED_DATE, SET_SELECTED_TIME, SET_SELECTED_SERVICE, SET_SELECTED_SERVICE_TYPE, SET_SELECTED_SUBSERVICE, SET_SELECTED_SUBSERVICE_OPTIONS, SET_MESSAGE, SET_CONFIRMATION, SET_CURRENT_BOOKING_DETAILS, SET_CURRENT_BOOKING_EMAIL } from '@/store/mutation-types';
export default (_a = {},
    /**
     * Store Availability
     * @param state vuex state
     * @param availability API-provided availability
     */
    _a[SET_AVAILABILITY] = function (state, availability) {
        state.availability = availability;
    },
    /**
     * Set a boolean to mark the availability request as geolocated
     * @param state vuex state
     * @param geolocated
     */
    _a[SET_GEOLOCATED_AVAILABILITY] = function (state, geolocated) {
        state.geolocatedAvailability = geolocated;
    },
    /**
     * Store a list of Point of Sales
     * @param state vuex state
     * @param list
     */
    _a[SET_POINT_OF_SALE_LIST] = function (state, list) {
        state.pointOfSaleResultList = list;
    },
    /**
     * Set a boolean to mark the point of sales list request as geolocated
     * @param state vuex state
     * @param geolocated
     */
    _a[SET_GEOLOCATED_POINT_OF_SALE_LIST] = function (state, geolocated) {
        state.geolocatedPointOfSaleList = geolocated;
    },
    /**
     * Set selected Point of Sale
     * @param state vuex state
     * @param pointOfSale selected Point of Sale
     */
    _a[SET_SELECTED_STANDALONE_POINT_OF_SALE] = function (state, pointOfSale) {
        state.selectedStandalonePointOfSale = pointOfSale;
    },
    /**
     * Set selected Service
     * @param state vuex state
     * @param service selected service
     */
    _a[SET_SELECTED_SERVICE] = function (state, service) {
        state.selectedService = service;
    },
    /**
     * Set selected Service Type
     * @param state vuex state
     * @param serviceType selected service type
     */
    _a[SET_SELECTED_SERVICE_TYPE] = function (state, serviceType) {
        state.selectedServiceType = serviceType;
    },
    /**
     * Set selected SubService
     * @param state vuex state
     * @param subServiceId selected SubService id
     */
    _a[SET_SELECTED_SUBSERVICE] = function (state, subServiceId) {
        state.selectedSubService = subServiceId;
    },
    /**
     * Set selected SubService categories options
     * @param state vuex state
     * @param category1OptionId category 1 option ID
     * @param category2OptionId category 1 option ID
     */
    _a[SET_SELECTED_SUBSERVICE_OPTIONS] = function (state, _a) {
        var category1OptionId = _a.category1OptionId, category2OptionId = _a.category2OptionId;
        state.selectedSubServiceCategory1OptionId = category1OptionId;
        state.selectedSubServiceCategory2OptionId = category2OptionId;
    },
    /**
     * Set selected Point of Sale
     * @param state vuex state
     * @param pointOfSale selected Point of Sale
     */
    _a[SET_SELECTED_POINT_OF_SALE] = function (state, pointOfSale) {
        state.selectedPointOfSale = pointOfSale;
    },
    /**
     * Set selected Date
     * @param state vuex state
     * @param theDate selected Date
     */
    _a[SET_SELECTED_DATE] = function (state, theDate) {
        state.selectedDate = theDate;
    },
    /**
     * Set selected Time
     * @param state vuex state
     * @param theTime selected Time
     */
    _a[SET_SELECTED_TIME] = function (state, theTime) {
        state.selectedTime = theTime;
    },
    /**
     * Set Message
     * @param state vuex state
     * @param theTime custom message
     */
    _a[SET_MESSAGE] = function (state, message) {
        state.message = message;
    },
    /**
     * Set booking confirmation
     * @param state vuex state
     * @param confirmation detailed booking informations
     */
    _a[SET_CONFIRMATION] = function (state, confirmation) {
        state.confirmation = confirmation;
    },
    /**
     * Set current booking details
     * @param state vuex state
     * @param bookingDetails detailed booking informations
     */
    _a[SET_CURRENT_BOOKING_DETAILS] = function (state, bookingDetails) {
        state.currentBooking = bookingDetails;
    },
    /**
     * Set current booking user's email
     * @param state vuex state
     * @param theTime selected Time
     */
    _a[SET_CURRENT_BOOKING_EMAIL] = function (state, email) {
        state.currentBookingEmail = email;
    },
    _a);
