import Vue from 'vue';

/**
 * IntersectionObserver is not defined ? Load polyfill
 */
if (typeof IntersectionObserver === 'undefined') {
    require('intersection-observer');
}

/**
 * Intersection Observer directive
 * 
 * Usage: 
 * <div v-observe="{ enter:customEnterCallback, leave:customLeaveCallback }">...</div>
 * 
 * - enter {function} Called when element is in viewport 
 * - leave {function} Called when element leaves viewport 
 * 
 */
Vue.directive('observe', {
    inserted: function(el, binding) {
        // binding.value should be an object containing two callback functions:
        // - enter
        // - leave 
        // called through binding.value.enter() / binding.value.leave() 

        let intersectionOptions = {
            root: null, // observe viewport
            rootMargin: '0px',
            threshold: 0.01
        };
        let observer = new IntersectionObserver((entries) => {
            let entry = entries[0];
            let inviewport = entry.intersectionRatio > intersectionOptions.threshold;
            // Apply change according to inviewport: call callback functions
            if (inviewport) {
                if (binding.value.enter) {
                    binding.value.enter();
                }
            } else {
                if (binding.value.leave) {
                    binding.value.leave();
                }
            }
        }, intersectionOptions);
        observer.observe(el);
    }
});
