<template>
    <transition name="page-fade" mode="out-in" v-on:before-enter="onTransitionBeforeEnter">
        <div v-if="translationLoaded" :key="currentLocale">
            <slot />
        </div>
        <div class="text_center" v-else>
            <loading-dots />
        </div>
    </transition>
</template>

<script>
/**
 * Localizer 
 * Watch market / locale change in the URL, trigger localisation data fetching accordingly. 
 * Manage redirection to default if something goes wrong. 
 */
import LoadingDots from '@/components/common/LoadingDots.vue';

export default {
    components: {
        LoadingDots
    },
    data: function() {
        return {
            translationLoaded: false,
            currentMarket: '',
            currentLocale: ''
        };
    },
    watch: {
        $route: function() {
            this.setMarket(this.$route.params.market, this.$route.query.lang || null);
        }
    },
    created: function() {
        this.setMarket(this.$route.params.market, this.$route.query.lang || null);
    },
    methods: {
        /**
         * Set Market and locale.
         * If provided market is not valid (comes from user entered URL),
         * then will fallback to default market. 
         * @param {String} market the two-letters market, for example 'fr'
         * @param {String} locale the five-letters market, for example 'en_UK'
         */
        setMarket(market, locale) {
            if (market && market !== this.currentMarket) {
                this.currentMarket = market;
                this.$I18nService.setMarket(market, locale)
                    .then(() => {
                        // setMarket will resolve when market's associated locale is fully loaded.
                        this.translationLoaded = true;
                    })
                    .catch((error) => {
                        // Ask service to load the default locale, 
                        // to make sure we have translations to display even on error pages. 
                        this.$I18nService.setLocale(this.$I18nService.getDefaultLocale())
                            .then(() => {
                                this.translationLoaded = true;
                            });
                    });
            }
        },
        /**
         *
         */
        onTransitionBeforeEnter() {
            this.$emit('before-enter');
        }
    }
};
</script>
