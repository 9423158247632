<template>
    <div class="col_md_6" v-if="service">
        <custom-image
            v-if="service.mainImage"
            :data="service.mainImage"
            class="wrapper full has-img-cover"
        ></custom-image>
        <div class="card-light mb-xs-4">
            <h3 class="text-14 mt-0 c-black-light">{{ service.name }}</h3>
            <p class="text-14 c_offblack pb-5 mb-0">{{ service.description }}</p>
            <div class="full_sep_light pt-5 mb-5" v-if="service.subServices && service.subServices.length > 0">
                <p class="text-14 fw_400 c-black-light" v-html="$t('fsh-subservice-select')"></p>
                <ul class="fw_300 pt-1">
                    <li
                        v-for="subservice in service.subServices"
                        :key="subservice.id"
                        class="custom_checkbox custom-checkbox-alternate pl-4"
                    >
                        <label class="p_rel text-12">
                            <input
                                :name="`subservice_${service.id}`"
                                type="radio"
                                :value="subservice.id"
                                v-model="subserviceModel"
                            />
                            <span class="check"></span>
                            <span class="text-14 c_offblack">{{ subservice.name }}</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="d_flex j_center">
                <router-link
                    :to="{
                        name: RouteNames.BOOK,
                        params: {
                            serviceId: service.id,
                            serviceType: ServiceTypes.INSTORE,
                            boutiqueId: singleBoutiqueId || null,
                            boutiqueSlug: singleBoutiqueSlug || null
                        },
                        query: routeQuery
                    }"
                    class="btn btn_black mw_300 hn_style fw_300 py-3 full_w"
                    v-on:click.native="trackButtonClick"
                    v-html="$t('common-book-an-appointment')"
                    data-cy-button="type-instore"
                >
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import RouteNames from '@/const/RouteNames';
import ServiceTypes from '@/const/ServiceTypes';
import CustomImage from '@/components/common/CustomImage.vue';

export default {
    data: () => ({
        RouteNames,
        ServiceTypes,
        subserviceModel: null
    }),
    props: {
        service: {
            type: Object, // Service
            default: null
        },
        singleBoutiqueId: {
            type: String,
            default: null
        },
        singleBoutiqueSlug: {
            type: String,
            default: null
        }
    },
    computed: {
        /**
         * Build the route query string params.
         * Avoid having empty params if they are unnecessary.
         */
        routeQuery() {
            return this.subserviceModel ? { subservice: this.subserviceModel } : {};
        }
    },
    watch: {
        service(newService) {
            this.setDefaultSubService();
        }
    },
    components: {
        CustomImage
    },
    created() {
        this.setDefaultSubservice();
    },
    methods: {
        /**
         * Define default subservice, if existing subServices,
         * to avoid the possibility of clicking the link without selecting one of the subServices.
         */
        setDefaultSubservice() {
            if (this.service.subServices && this.service.subServices.length > 0) {
                this.subserviceModel = this.service.subServices[0].id;
            } else {
                this.subserviceModel = null;
            }
        },
        /**
         * 
         */
        trackButtonClick() {
            const event = {
                eventcategory: 'appointment booking',
                eventaction: this.service.id
            };
            const subservice = this.subserviceModel
                ? this.service.subServices.find((sub) => sub.id === this.subserviceModel) : null;
            if (subservice) {
                event.eventlabel = subservice.code;
            }
            this.$Tracker.event(event);
        }
    }
};
</script>
