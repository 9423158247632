var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"p_rel"},[_c('div',{staticClass:"prefooter"},[(!_vm.retailSpecial)?[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
                            name: _vm.RouteNames.BOOK_MANAGE,
                            params: {
                                division: _vm.divisionSeoName,
                                market: this.$I18nService.getCurrentMarket()
                            }
                        },"id":"bookingmanage_link"},domProps:{"innerHTML":_vm._s(_vm.$t('footer-manage-appointment'))},nativeOn:{"click":function($event){return _vm.trackButtonClick('manage my rdv')}}})],1),_c('li',[_c('a',{attrs:{"href":_vm.dotComURL,"target":"_blank"},on:{"click":function($event){return _vm.trackButtonClick('chanel.com')}}},[_vm._v("chanel.com")])]),_c('li',[(_vm.division.code === _vm.DivisionsCodes.FSH)?_c('a',{attrs:{"href":_vm.contactURL,"target":"_blank","data-cy-link":"contact-corporate"},domProps:{"innerHTML":_vm._s(_vm.$t('common-contact-us'))},on:{"click":function($event){return _vm.trackButtonClick('contact')}}}):_c('router-link',{attrs:{"to":{
                            name: _vm.RouteNames.CONTACT,
                            params: {
                                division: _vm.divisionSeoName,
                                market: this.$I18nService.getCurrentMarket()
                            }
                        },"data-cy-link":"contact"},domProps:{"innerHTML":_vm._s(_vm.$t('common-contact-us'))},nativeOn:{"click":function($event){return _vm.trackButtonClick('contact')}}})],1),_c('li',[_c('a',{attrs:{"href":_vm.termsAndConditionsURL,"target":"_blank"},domProps:{"innerHTML":_vm._s(_vm.$t('common-terms-and-conditions'))},on:{"click":function($event){return _vm.trackButtonClick('policies')}}})])])]:_vm._e()],2),_c('div',{staticClass:"footer is_flex",class:{ r_footer: _vm.retailSpecial }},[_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo text_center"},[_c('picture',[_c('img',{attrs:{"src":require("../../assets/chanel-rdv-logo-white.svg"),"alt":""}})])])
}]

export { render, staticRenderFns }