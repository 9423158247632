<template>
    <div class="custom-select p-rel full-w pr-4 d_flex a_center" :tabindex="tabindex" >
        <div class="selected-option text-10 text-md-11 hn_style full_w" :class="{ open: open }" @click="toggleOpen">
            {{ selected && selected.value ? selected.label : defaultLabel }}
        </div>
        <transition name="component-fade">
            <div class="custom-select-wrapper" v-if="open">
                <p class="hide_md hn_style text-11 mb-0 d_flex j_space_btw a_center full_w">
                    <span v-html="$t('servicelist-filter-category')"></span>
                    <button class="nbs btn_close" @click="toggleOpen"><img src="@/assets/light-close.svg" alt=""></button></p>
                <ul class="items text-14 fw_300 m-0" key="items">
                    <li :class="selected === option ? 'selected' : ''" v-for="(option, i) of options" :key="i" @click="onOptionSelect(option)">
                        {{ option.label }}
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'CustomSelect',
    data: () => ({
        open: false,
        selected: null
    }),
    props: {
        tabindex: {
            type: Number,
            default: 0
        },
        options: {
            type: Array,
            required: true,
            default: () => []
        },
        defaultLabel: {
            type: String,
            default: ''
        }
    },
    methods: {
        close() {
            this.open = false;
        },
        toggleOpen() {
            this.open = !this.open;
        },
        /**
         * Option selection handler
         * @param {Object} option Selected option, for example { value: 'foo', label: 'Foo' }
         */
        onOptionSelect(option) {
            this.selected = option;
            this.open = false;
            // Emit value only
            this.$emit('input', option.value);
        }
    }
};
</script>
