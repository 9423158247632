export var SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export var SET_AUTH_TOKEN_EXPIRATION = 'SET_AUTH_TOKEN_EXPIRATION';
export var SET_AUTH_USERNAME = 'SET_AUTH_USERNAME';
export var SET_USER = 'SET_USER';
export var SET_AVAILABILITY = 'SET_AVAILABILITY';
export var SET_GEOLOCATED_AVAILABILITY = 'SET_GEOLOCATED_AVAILABILITY';
export var SET_POINT_OF_SALE_LIST = 'SET_POINT_OF_SALE_LIST';
export var SET_GEOLOCATED_POINT_OF_SALE_LIST = 'SET_GEOLOCATED_POINT_OF_SALE_LIST';
export var SET_SELECTED_STANDALONE_POINT_OF_SALE = 'SET_SELECTED_STANDALONE_POINT_OF_SALE';
export var SET_SELECTED_SERVICE = 'SET_SELECTED_SERVICE';
export var SET_SELECTED_SERVICE_TYPE = 'SET_SELECTED_SERVICE_TYPE';
export var SET_SELECTED_SUBSERVICE = 'SET_SELECTED_SUBSERVICE';
export var SET_SELECTED_SUBSERVICE_OPTIONS = 'SET_SELECTED_SUBSERVICE_OPTIONS';
export var SET_SELECTED_POINT_OF_SALE = 'SET_SELECTED_POINT_OF_SALE';
export var SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export var SET_SELECTED_TIME = 'SET_SELECTED_TIME';
export var SET_MESSAGE = 'SET_MESSAGE';
export var SET_CONFIRMATION = 'SET_CONFIRMATION';
export var SET_CURRENT_BOOKING_DETAILS = 'SET_CURRENT_BOOKING_DETAILS';
export var SET_CURRENT_BOOKING_EMAIL = 'SET_CURRENT_BOOKING_EMAIL';
export var SET_SERVICE_LIST = 'SET_SERVICE_LIST';
export var SET_SERVICE_THEMATIC_FILTER = 'SET_SERVICE_THEMATIC_FILTER';
export var SET_SERVICE_TYPE_FILTER = 'SET_SERVICE_TYPE_FILTER';
