import { DivisionsStack } from '@/const/Divisions';
/**
 * Return the Division definition from its SEO name
 * @param seoName division name in URL, for example 'mode'
 * @returns complete Division definition
 */
export function divisionFromSeoName(seoName) {
    return DivisionsStack.find(function (div) { return div.seoName === seoName; });
}
;
/**
 * Return the Division definition from its ID
 * @param seoName division ID, for example 'FSH'
 * @returns complete Division definition
 */
export function divisionFromCode(code) {
    return DivisionsStack.find(function (div) { return div.code === code; });
}
;
/**
 * Return the Division definition from its API code
 * @param apiCode division code used by API, for example 'MODE' or 'PB'
 * @returns complete Division definition
 */
export function divisionFromApiCode(code) {
    return DivisionsStack.find(function (div) { return div.apiCode === code; });
}
;
