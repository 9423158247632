<template>
    <section class="container p_rel">
        <div class="pb-8 mb-md-4">
            <div class="col pt-8 pb-8">
                <div class="inner">
                    <div class="content text_center pt-2 pb-1">
                        <h1 class="f_26_40 hn_style">404</h1>
                        <p class="mb-6">
                            {{ message ? message : $t('common-generic-error') }}
                        </p>
                        <router-link
                            :to="{
                                name: RouteNames.HOME,
                                params: {
                                    division: divisionSeoName,
                                    market: this.$I18nService.getCurrentMarket()
                                }
                            }"
                            class="btn btn_black mb-4 mt-md-2"
                            v-on:click.native="
                                trackButtonClick('book an appointment')
                            "
                        >
                            {{ $t('common-book-an-appointment') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Division from '@/mixins/Division.js';
import RouteNames from '@/const/RouteNames';

export default {
    name: 'NotFoundPage',
    mixins: [Division],
    data: () => ({
        RouteNames
    }),
    props: {
        message: {
            type: String,
            default: ''
        }
    },
    methods: {
        trackButtonClick(description) {
            // this.$Tracker.event({
            //     eventcategory: '404',
            //     eventaction: description
            // });
        }
    }
};
</script>
