/**
 * Constants to define Routes Names
 */
const RouteNames = {
    HOME: 'home',
    HOME_BOUTIQUES: 'homeboutiques',
    HOME_BOUTIQUE: 'homeboutique',
    HOME_WHOLESALE_GROUP: 'homewholesalegroup',
    SERVICE_LIST: 'servicelist',
    SERVICE_DETAILS: 'servicedetail',
    // Date&POS booking funnel
    BOOK_SUBSERVICE_OPTIONS: 'booksubserviceoptions',
    BOOK: 'book',
    BOOK_FORM: 'bookform',
    BOOK_CONFIRMATION: 'bookconfirmation',
    // Booking management
    BOOK_MANAGE: 'bookmanage',
    BOOK_UPDATE: 'bookupdate',
    BOOK_UPDATE_CONFIRMATION: 'bookupdateconfirmation',
    // Classic booking funnel
    BOOK_SERVICE: 'bookservice',
    BOOK_CONFIRM: 'bookingconfirm',
    BOOK_CANCEL: 'bookingcancel',
    // Paid services booking funnel
    BOOK_PAID: 'bookpaid',
    BOOK_PAID_FORM: 'bookpaidform',
    BOOK_PAID_CONFIRMATION: 'bookpaidconfirmation',
    //
    RETAIL_BOOK_CANCEL: 'retail_bookingcancel',
    PRESENCE: 'presence',
    CLIENT_ATTENDANCE: 'client_attendance',
    CONTACT: 'contact',
    TRYON: 'tryon',
    TRYON_CONNECTED: 'tryon_connected',
    TRYON_CLOSED: 'tryon_closed',
    VOC: 'voc',
    HEALTH: 'health',
    LOCALIZED_404: 'localized_404',
    MISSING_DIVISION_404: 'missing_division_404',
    GLOBAL_404: '404'
};
export default RouteNames;
