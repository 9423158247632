<template>
    <footer class="p_rel">
        <div class="prefooter">
            <template v-if="!retailSpecial">
                <ul>
                    <li>
                        <router-link
                            :to="{
                                name: RouteNames.BOOK_MANAGE,
                                params: {
                                    division: divisionSeoName,
                                    market: this.$I18nService.getCurrentMarket()
                                }
                            }"
                            v-on:click.native="
                                trackButtonClick('manage my rdv')
                            "
                            id="bookingmanage_link"
                            v-html="$t('footer-manage-appointment')"
                        >
                        </router-link>
                    </li>
                    <li>
                        <a
                            :href="dotComURL"
                            target="_blank"
                            v-on:click="trackButtonClick('chanel.com')"
                            >chanel.com</a
                        >
                    </li>
                    <li>
                        <a
                            v-if="division.code === DivisionsCodes.FSH"
                            :href="contactURL"
                            target="_blank"
                            v-on:click="trackButtonClick('contact')"
                            v-html="$t('common-contact-us')"
                            data-cy-link="contact-corporate"
                        ></a>
                        <router-link
                            v-else
                            :to="{
                                name: RouteNames.CONTACT,
                                params: {
                                    division: divisionSeoName,
                                    market: this.$I18nService.getCurrentMarket()
                                }
                            }"
                            v-on:click.native="trackButtonClick('contact')"
                            data-cy-link="contact"
                            v-html="$t('common-contact-us')"
                        ></router-link>
                    </li>
                    <li>
                        <a
                            :href="termsAndConditionsURL"
                            target="_blank"
                            v-on:click="trackButtonClick('policies')"
                            v-html="$t('common-terms-and-conditions')"
                        ></a>
                    </li>
                </ul>
            </template>
        </div>
        <div v-bind:class="{ r_footer: retailSpecial }" class="footer is_flex">
            <div class="logo text_center">
                <picture>
                    <img src="../../assets/chanel-rdv-logo-white.svg" alt="" />
                </picture>
            </div>
        </div>
    </footer>
</template>
<script>
import Division from '@/mixins/Division.js';
import { DivisionsCodes } from '@/const/Divisions';
import RouteNames from '@/const/RouteNames';
import Config from '@/utils/config';

export default {
    mixins: [Division],
    data: () => ({
        RouteNames,
        DivisionsCodes
    }),
    props: {
        retailSpecial: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        /**
         * Link to chanel.com will preserve selected *market* only,
         * and eventually fallback to defined alternative
         */
        dotComURL() {
            const dotcomLocaleCode = Config.getDotComMarketLocaleCode(this.$I18nService.getCurrentMarket());
            return `https://www.chanel.com/${dotcomLocaleCode}`;
        },
        /**
         * Link to terms & conditions corporate service
         */
        termsAndConditionsURL() {
            const corporateLocaleCode = Config.getCorporateMarketLocaleCode(this.$I18nService.getCurrentMarketLocaleCode());
            return `https://services.chanel.com/${corporateLocaleCode}/policies/termsofsale/`;
        },
        /**
         * 
         * Link to contact corporate service
         */
        contactURL() {
            const corporateLocaleCode = Config.getCorporateMarketLocaleCode(this.$I18nService.getCurrentMarketLocaleCode());
            return `https://services.chanel.com/${corporateLocaleCode}/contact/`;
        }
    },
    methods: {
        trackButtonClick(description) {
            this.$Tracker.event({
                eventcategory: 'footer',
                eventaction: description
            });
        }
    }
};
</script>
