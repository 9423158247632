<template>
    <transition name="modal">
        <div class="modal-mask" v-if="display">
            <div class="modal-wrapper" :class="displayMode">
                <div class="modal-container p-rel">
                    <div class="text-right">
                        <button
                            class="modal-close-button"
                            @click="$emit('close')"
                        ></button>
                    </div>
                    <div class="modal-header">
                        <h2 class="text-16" :class="headerAlign == 'md-left' ? 'text-xs-sm-center' : 'text_center'">
                            <slot name="header"></slot>
                        </h2>
                    </div>

                    <div class="modal-body">
                        <slot name="body"></slot>
                    </div>

                    <div class="modal-actions">
                        <slot name="actions"></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="js">

export default {
    props: {
        display: {
            type: Boolean,
            default: false
        },
        displayMode: {
            type: String,
            default: 'xs-bottom' // available options are 'center' or 'xs-bottom'
        },
        headerAlign: {
            type: String,
            default: 'center' // available options are 'center' or 'md-left'
        }
    }
};
</script>
