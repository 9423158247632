var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SET_AVAILABILITY, SET_GEOLOCATED_AVAILABILITY, SET_POINT_OF_SALE_LIST, SET_SELECTED_STANDALONE_POINT_OF_SALE, SET_SELECTED_POINT_OF_SALE, SET_SELECTED_DATE, SET_SELECTED_TIME, SET_SELECTED_SERVICE, SET_SELECTED_SERVICE_TYPE, SET_SELECTED_SUBSERVICE, SET_SELECTED_SUBSERVICE_OPTIONS, SET_CONFIRMATION, SET_GEOLOCATED_POINT_OF_SALE_LIST, SET_CURRENT_BOOKING_DETAILS, SET_CURRENT_BOOKING_EMAIL } from '@/store/mutation-types';
// JS import workaround
var API = require('@/api.js').default;
/**
 * Fetch available slots according to given params
 * @param param0 vuex action context
 * @param params availability params
 * @returns
 */
export function fetchAvailability(_a, params) {
    var commit = _a.commit;
    return __awaiter(this, void 0, void 0, function () {
        var availability, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API.getAvailabilities(params)];
                case 1:
                    availability = _b.sent();
                    if (availability) {
                        // Freeze object as there will not be any change there
                        Object.freeze(availability);
                        commit(SET_AVAILABILITY, availability);
                        // If request is sent with lat / lng params, then we can say that this is a geolocated request. 
                        commit(SET_GEOLOCATED_AVAILABILITY, (params.lat !== undefined && params.lng !== undefined));
                        return [2 /*return*/, Promise.resolve(availability)];
                    }
                    return [2 /*return*/, Promise.reject(Error())];
                case 2:
                    error_1 = _b.sent();
                    return [2 /*return*/, Promise.reject(error_1)];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * Fetch Point of Sale standalone list according to given params
 * @param param0 vuex action context
 * @param params POS fetch params
 * @returns
 */
export function fetchPointOfSaleList(_a, params) {
    var commit = _a.commit;
    return __awaiter(this, void 0, void 0, function () {
        var response, posList, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API.getPointOfSales(params)];
                case 1:
                    response = _b.sent();
                    if (response && response.content) {
                        posList = response.content;
                        Object.freeze(posList);
                        commit(SET_POINT_OF_SALE_LIST, posList);
                        // If request is sent with lat / lng params, then we can say that this is a geolocated request. 
                        commit(SET_GEOLOCATED_POINT_OF_SALE_LIST, (params.lat !== undefined && params.lng !== undefined));
                        return [2 /*return*/, Promise.resolve(posList)];
                    }
                    return [2 /*return*/, Promise.reject(Error())];
                case 2:
                    error_2 = _b.sent();
                    return [2 /*return*/, Promise.reject(error_2)];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * Set selected standalone Point of Sale
 * @param param0 vuex action context
 * @param id Point of Sale ID
 */
export function setSelectedStandalonePointOfSale(_a, id) {
    var _b;
    var commit = _a.commit, state = _a.state;
    var pointOfSaleResult = (_b = state.pointOfSaleResultList) === null || _b === void 0 ? void 0 : _b.find(function (posResult) { return posResult.pos.id === id; });
    if (pointOfSaleResult) {
        commit(SET_SELECTED_STANDALONE_POINT_OF_SALE, pointOfSaleResult.pos);
        return pointOfSaleResult.pos;
    }
    return pointOfSaleResult;
}
/**
 * Unset selected standalone Point of Sale
 * @param param0 vuex action context
 */
export function unsetSelectedStandalonePointOfSale(_a) {
    var commit = _a.commit;
    commit(SET_SELECTED_STANDALONE_POINT_OF_SALE, undefined);
}
/**
 * Set selected Service & SubService.
 * Will return null if anything went wrong: no service or no subservice found, or missing subservice.
 * @param param0 vuex action context
 * @param serviceId service single ID, for example 'tarot-revelations'
 * @param subServiceId subservice single ID. Must be part of serviceId's subServices.
 */
export function setSelectedService(_a, _b) {
    var _c, _d, _e, _f;
    var commit = _a.commit, rootState = _a.rootState;
    var serviceId = _b.serviceId, serviceType = _b.serviceType, subServiceId = _b.subServiceId, category1OptionId = _b.category1OptionId, category2OptionId = _b.category2OptionId;
    var serviceResult = rootState.service.services.find(function (service) { return service.id === serviceId; });
    if (serviceResult) {
        commit(SET_SELECTED_SERVICE, serviceResult);
        if (serviceType) {
            commit(SET_SELECTED_SERVICE_TYPE, serviceType);
        }
        if (serviceResult.subServices && serviceResult.subServices.length > 0) {
            // If the selected service has subServices, must also select one of the subServices.
            if (subServiceId) {
                // Check if this subService is actually part of this service. 
                // Has to force-cast everything as string because ID can be provided as number by API.
                var subService = serviceResult.subServices.find(function (sSvc) { return String(sSvc.id) === String(subServiceId); });
                if (subService) {
                    // If options categories are provided, check existence of those options in selected subservice, 
                    // then store those values. 
                    var categoriesOptions = {
                        category1OptionId: undefined,
                        category2OptionId: undefined
                    };
                    if (category1OptionId) {
                        var category1Option = (_d = (_c = subService.options) === null || _c === void 0 ? void 0 : _c.category1.options) === null || _d === void 0 ? void 0 : _d.find(function (c1Opt) { return c1Opt.id === category1OptionId; });
                        if (category1Option) {
                            categoriesOptions.category1OptionId = category1Option.id;
                        }
                        else {
                            console.warn('ERROR: category1Option not found for provided subservice');
                            return null;
                        }
                    }
                    if (category2OptionId) {
                        var category2Option = (_f = (_e = subService.options) === null || _e === void 0 ? void 0 : _e.category2.options) === null || _f === void 0 ? void 0 : _f.find(function (c1Opt) { return c1Opt.id === category2OptionId; });
                        if (category2Option) {
                            categoriesOptions.category2OptionId = category2Option.id;
                        }
                        else {
                            console.warn('ERROR: category2Option not found for provided subservice');
                            return null;
                        }
                    }
                    commit(SET_SELECTED_SUBSERVICE, subServiceId);
                    commit(SET_SELECTED_SUBSERVICE_OPTIONS, categoriesOptions);
                    return serviceResult;
                }
                return null;
            }
            else {
                // subService is missing.
                console.warn("ERROR: Missing subService for '".concat(serviceId, "'"));
                return null;
            }
        }
        // Empty the selected subservice & options if not defined, to avoid persistance
        commit(SET_SELECTED_SUBSERVICE, undefined);
        commit(SET_SELECTED_SUBSERVICE_OPTIONS, { category1OptionId: undefined, category2OptionId: undefined });
        return serviceResult;
    }
    return null;
}
/**
 * Unset selected Service
 * @param param0 vuex action context
 */
export function unsetSelectedService(_a) {
    var commit = _a.commit;
    commit(SET_SELECTED_SERVICE, undefined);
}
/**
 * Set selected sub-service
 * @param param0 vuex action context
 * @param subServiceId subservice single ID
 */
export function setSelectedSubService(_a, subServiceId) {
    var commit = _a.commit, state = _a.state;
    var selectedService = state.selectedService;
    // Selecting a subservice is only possible if a service is already selected
    if (selectedService && selectedService.subServices) {
        var subService = selectedService.subServices.find(function (sSvc) { return sSvc.id === subServiceId; });
        if (subService) {
            commit(SET_SELECTED_SUBSERVICE, subServiceId);
            return selectedService;
        }
        return null;
    }
    return null;
}
/**
 * Set selected Point of Sale
 * @param param0 vuex action context
 * @param id Point of Sale ID
 */
export function setSelectedPointOfSale(_a, id) {
    var _b;
    var commit = _a.commit, state = _a.state;
    var posResult = (_b = state.availability) === null || _b === void 0 ? void 0 : _b.posDetails.find(function (pR) { return pR.pos.id === id; });
    if (posResult) {
        commit(SET_SELECTED_POINT_OF_SALE, posResult.pos);
        return posResult.pos;
    }
    return posResult;
}
/**
 * Unset selected Point of Sale
 * @param param0 vuex action context
 */
export function unsetSelectedPointOfSale(_a) {
    var commit = _a.commit;
    commit(SET_SELECTED_POINT_OF_SALE, undefined);
}
/**
 * Set selected Date
 * @param param0 vuex action context
 * @param isoDate Date iso format, for example: "2022-03-17"
 */
export function setSelectedDate(_a, isoDate) {
    var _b;
    var commit = _a.commit, state = _a.state;
    var dateResult = (_b = state.availability) === null || _b === void 0 ? void 0 : _b.dates.find(function (dR) { return dR.isoDate === isoDate; });
    if (dateResult) {
        commit(SET_SELECTED_DATE, dateResult);
    }
    return dateResult;
}
/**
 * Unset selected Date
 * @param param0 vuex action context
 */
export function unsetSelectedDate(_a) {
    var commit = _a.commit;
    commit(SET_SELECTED_DATE, undefined);
}
/**
 * Set selected Time
 * @param param0 vuex action context
 * @param isoTime Time iso format, for example: "15:30"
 * @returns
 */
export function setSelectedTime(_a, isoTime) {
    var commit = _a.commit, state = _a.state, getters = _a.getters;
    // Get the BookingTime object amongst the selected available date
    var times = getters.getAvailableTimes;
    var timeResult = times.find(function (time) { return time.isoTime === isoTime; });
    if (timeResult) {
        commit(SET_SELECTED_TIME, timeResult);
    }
    return timeResult;
}
/**
 * Unset selected Time
 * @param param0 vuex action context
 */
export function unsetSelectedTime(_a) {
    var commit = _a.commit;
    commit(SET_SELECTED_TIME, undefined);
}
/**
 * Post a booking request
 * @param param0 vuex action context
 * @param data complete form data for booking request
 * @param slotId slot id
 * @returns API confirmation object with booking details
 */
export function postBookingRequest(_a, _b) {
    var commit = _a.commit;
    var data = _b.data, slotId = _b.slotId;
    return __awaiter(this, void 0, void 0, function () {
        var result, error_3;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API.postMultipartBookingRequest(data, slotId)];
                case 1:
                    result = _c.sent();
                    if (result && !result.error) {
                        // Freeze object as there will not be any change there
                        Object.freeze(result);
                        commit(SET_CONFIRMATION, result);
                        return [2 /*return*/, Promise.resolve(result)];
                    }
                    else {
                        return [2 /*return*/, Promise.reject(result)];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _c.sent();
                    return [2 /*return*/, Promise.reject(error_3)];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * Post a paid service booking request
 * @param param0 vuex action context
 * @param data complete form data for booking request
 * @param slotId slot id
 * @returns API confirmation object with booking details
 */
export function postPaidBookingRequest(_a, data) {
    var commit = _a.commit;
    return __awaiter(this, void 0, void 0, function () {
        var result, error_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API.postPaidBookingRequest(data)];
                case 1:
                    result = _b.sent();
                    if (result && !result.error) {
                        // Freeze object as there will not be any change there
                        Object.freeze(result);
                        commit(SET_CONFIRMATION, result);
                        return [2 /*return*/, Promise.resolve(result)];
                    }
                    else {
                        return [2 /*return*/, Promise.reject(result)];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _b.sent();
                    return [2 /*return*/, Promise.reject(error_4)];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * Flush booking selected params, to be typically used after booking confirmation.
 * @param param0 vuex action context
 */
export function flushSelectedParameters(_a) {
    var dispatch = _a.dispatch;
    dispatch('unsetSelectedPointOfSale');
    // TODO: 
    // Flush all parameters after BookingConfirmation page improvement:
    // - selected service (still required for end time calculation)
    // - selected date 
    // - selected time 
}
/**
 * Post a booking update request, for an existing booking
 * @param param0 vuex actions context
 * @param bookingId the required booking Id
 * @param email the email address associated to the required booking
 * @returns
 */
export function postBookingUpdateRequest(_a, _b) {
    var commit = _a.commit;
    var bookingId = _b.bookingId, originalBookingId = _b.originalBookingId, email = _b.email;
    return __awaiter(this, void 0, void 0, function () {
        var result, error_5;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API.updateBooking(bookingId, originalBookingId, email)];
                case 1:
                    result = _c.sent();
                    if (result && !result.error) {
                        // Freeze object as there will not be any change there
                        Object.freeze(result);
                        commit(SET_CONFIRMATION, result);
                        return [2 /*return*/, Promise.resolve(result)];
                    }
                    else {
                        return [2 /*return*/, Promise.reject(result)];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_5 = _c.sent();
                    return [2 /*return*/, Promise.reject(error_5)];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * Fetch detailed informations about some existing booking
 * @param param0 vuex action context
 * @param bookingId the required booking Id
 * @param email the email address associated to the required booking
 * @returns
 */
export function fetchBookingDetails(_a, _b) {
    var commit = _a.commit;
    var bookingId = _b.bookingId, email = _b.email;
    return __awaiter(this, void 0, void 0, function () {
        var result, error_6;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API.getBookingDetails(bookingId, email)];
                case 1:
                    result = _c.sent();
                    if (result && !result.error) {
                        commit(SET_CURRENT_BOOKING_EMAIL, email);
                        // Freeze object as there will not be any change there
                        Object.freeze(result);
                        commit(SET_CURRENT_BOOKING_DETAILS, result);
                        return [2 /*return*/, Promise.resolve(result)];
                    }
                    else {
                        return [2 /*return*/, Promise.reject(result)];
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_6 = _c.sent();
                    return [2 /*return*/, Promise.reject(error_6)];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 *
 * @param param0 vuex action context
 */
export function unsetCurrentBookingDetails(_a) {
    var commit = _a.commit;
    commit(SET_CURRENT_BOOKING_EMAIL, undefined);
    commit(SET_CURRENT_BOOKING_DETAILS, undefined);
}
