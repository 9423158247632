<template>
    <header class="header container">
        <div class="inner_header col is_flex j_center a_sm_center a_flex_start">
            <router-link v-if="clickable"
                :to="homeRoute"
                class="header_logo p_rel black">
                <p>CHANEL RENDEZ-VOUS BEAUTÉ</p>
            </router-link>
            <div v-else to="/" class="header_logo p_rel black">
                <p>CHANEL RENDEZ-VOUS BEAUTÉ</p>
            </div>
        </div>
    </header>
</template>
<script>
import Division from '@/mixins/Division.js';
import RouteNames from '@/const/RouteNames';

export default {
    mixins: [Division],
    props: {
        clickable: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        /**
         * Scoped for single Boutique only
         * @return {string|boolean} single boutique slug like 'chanel-paris-51-montaigne', or false if not scoped to single boutique
         */
        singleBoutiqueSlug() {
            return [RouteNames.HOME, RouteNames.HOME_BOUTIQUE, RouteNames.BOOK].includes(this.$route.name) &&
                this.$route.params.boutiqueSlug ? this.$route.params.boutiqueSlug : false;
        },
        /**
         * Build the home route parameters according to current URL
         */
        homeRoute() {
            let name = this.singleBoutiqueSlug ? RouteNames.HOME_BOUTIQUE : RouteNames.HOME;
            const params = {
                market: this.$I18nService.getCurrentMarket(),
                division: this.divisionSeoName,
                boutique: this.singleBoutiqueSlug || null
            };
            if (this.$route.query.wholesale) {
                name = RouteNames.HOME_WHOLESALE_GROUP;
                params.wholesaleGroupSlug = this.$route.query.wholesale;
            }
            return {
                name,
                params
            };
        }
    }
};
</script>
