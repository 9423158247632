<template>
<div id="app">
    <header class="header container header-alternate">
        <div class="inner_header col is_flex j_center a_sm_center a_flex_start">
            <div to="/" class="header_logo p_rel black">
                <p>CHANEL RENDEZ-VOUS BEAUTÉ</p>
            </div>
        </div>
    </header>
    <main>
        <section class="d_md_flex">
            <div class="mx-auto py-9 mb-xs-2 my-md-8">
                <div class="col pt-8 pb-8">
                    <div class="inner">
                        <div class="content text_center">
                            <h2 class="f_14_24 hn_style">
                                Notre site est temporairement<br />
                                indisponible pour cause de<br />
                                maintenance
                            </h2>
                            <p>
                                Veuillez réessayer ultérieurement afin de découvrir d’avantage de services
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</div>
</template>

<script>
/**
 * Maintenace Mode
 * Minimal page, must be kept as simple as possible, 
 * without any dependancy to any other feature or component. 
 */
export default {
    name: 'Maintenance'
};
</script>
