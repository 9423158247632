/**
 * Cookies
 */
class _Cookies {
    /**
     * Get a cookie value 
     * @param {String} name the cookie name 
     */
    getCookieValue(name) {
        var b = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }
    
    /**
     * Set a cookie
     * @param {String} name the cookie name
     * @param {String} value the cookie value 
     * @param {Number} days the validity duration in days 
     */
    setCookieValue(name, value, days) {
        let expires = '';
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toGMTString();
        }
        document.cookie = name + '=' + value + expires + '; path=/;SameSite=Lax';
    }
}

const Cookies = new _Cookies();
export default Cookies;
