export var DivisionsCodes = {
    FSH: 'FSH',
    FNB: 'FNB',
    WFJ: 'WFJ'
};
export var Divisions = {
    FSH: {
        code: DivisionsCodes.FSH,
        seoName: 'mode',
        apiCode: 'MODE',
        apiSvcType: 'MODE',
        fullName: 'Fashion'
    },
    FNB: {
        code: DivisionsCodes.FNB,
        seoName: 'beauty',
        apiCode: 'PB',
        apiSvcType: 'BEAUTY',
        fullName: 'Fragrance & Beauty'
    },
    WFJ: {
        code: DivisionsCodes.WFJ,
        seoName: 'watches-jewellery',
        apiCode: 'HJ',
        apiSvcType: 'HJ',
        fullName: 'Watches & Jewellery'
    }
};
export var DivisionsStack = [
    Divisions.FSH,
    Divisions.FNB,
    Divisions.WFJ
];
