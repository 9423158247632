var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SET_SERVICE_LIST, SET_SERVICE_THEMATIC_FILTER, SET_SERVICE_TYPE_FILTER } from '@/store/mutation-types';
import ServiceTypes from '@/const/ServiceTypes';
// JS import workaround
var API = require('@/api.js').default;
/**
 * Fetch services according to given params
 * @param param0 vuex action context
 * @param params services params
 * @returns
 */
export function fetchServiceList(_a, params) {
    var commit = _a.commit;
    return __awaiter(this, void 0, void 0, function () {
        var services, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, API.getServiceList(params.divisionCode)];
                case 1:
                    services = _b.sent();
                    if (services) {
                        // Freeze object as there will not be any change there
                        Object.freeze(services);
                        commit(SET_SERVICE_LIST, services);
                        return [2 /*return*/, Promise.resolve(services)];
                    }
                    return [2 /*return*/, Promise.reject(Error())];
                case 2:
                    error_1 = _b.sent();
                    return [2 /*return*/, Promise.reject(error_1)];
                case 3: return [2 /*return*/];
            }
        });
    });
}
/**
 * Set a thematic filter on services
 * @param param0 vuex action context
 * @param thematicId selected thematic id to be used as services filter
 */
export function setThematicFilter(_a, thematicId) {
    var commit = _a.commit, getters = _a.getters;
    if (getters.getThematicKeys.includes(thematicId)) {
        commit(SET_SERVICE_THEMATIC_FILTER, thematicId);
    }
    else {
        commit(SET_SERVICE_THEMATIC_FILTER, undefined);
    }
}
/**
 * Set a service type filter on services
 * @param param0 vuex action context
 * @param serviceType selected service type to be used as services filter
 */
export function setServiceTypeFilter(_a, serviceType) {
    var commit = _a.commit;
    if ([ServiceTypes.DIGITAL, ServiceTypes.INSTORE].includes(serviceType)) {
        commit(SET_SERVICE_TYPE_FILTER, serviceType);
    }
    else {
        commit(SET_SERVICE_TYPE_FILTER, undefined);
    }
}
