<template>
    <div class="locale-selector-wrapper">
        <div class="locale-selector">
            <label class="grey f_12" for="locale-selector" v-html="$t('locale-selector-notice')"></label>
            <button class="locale-current nbs white f_12" @click.prevent="onButtonClick">
                {{ $t(`market-${currentMarket}`) }} - {{ $t(`locale-${currentLocale}`) }}
            </button>
        </div>
        <modal :display="displayModal" @close="onModalClose">
            <template slot="header">
                <span v-html="$t('locale-selector-title', { market: $t(`market-${$route.params.market}`) })"></span>
            </template>
            <template slot="body">
                <div class="text_center">
                    <p v-html="$t('locale-selector-content')"></p>
                    <h3 class="hn_style text_center h3" v-html="$t('locale-selector-change-market')"></h3>
                    <div class="select">
                        <select v-on:change="onMarketChange" v-model="selectedMarket" id="market-selector">
                            <option v-for="(market, index) in supportedMarkets"
                                :key="index"
                                :value="market">
                                {{ $t(`market-${market}`) }}
                            </option>
                        </select>
                    </div>
                    <h3 class="hn_style text_center h3" v-html="$t('locale-selector-change-locale')"></h3>
                    <div class="select">
                        <select v-on:change="onLocaleChange" v-model="selectedLocale" id="locale-selector">
                            <option v-for="(locale, index) in marketAvailableLocales"
                                :key="index"
                                :value="locale">
                                {{ $t(`locale-${locale}`) }}
                            </option>
                        </select>
                    </div>
                    <button
                        :disabled="!canSubmit || processing"
                        class="btn btn_black p-3"
                        @click="onValidate"
                        v-html="$t('common-submit-label')">
                    </button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import RouteNames from '@/const/RouteNames';
import Config from '@/utils/config';
import Division from '@/mixins/Division';
import Modal from '@/components/common/Modal.vue';
import { divisionFromSeoName } from '@/utils/DivisionsHelper';

export default {
    data: function() {
        return {
            // Currently active market and locale
            currentMarket: null,
            currentLocale: null,
            // Selected (but not necessarily applied) market and locale
            selectedMarket: null,
            selectedLocale: null,
            displayModal: false,
            processing: false
        };
    },
    components: {
        Modal
    },
    mixins: [
        Division
    ],
    computed: {
        /**
         * Get the list of supported markets
         * Only includes markets that are available for the current division. 
         */
        supportedMarkets() {
            const currentDiv = divisionFromSeoName(this.$route.params.division);
            if (currentDiv) {
                const supportedMarkets = Config.getSupportedMarketsByDivision(this.division.apiCode);
                return supportedMarkets.map(market => market.toLowerCase());
            } else {
                return [];
            }
        },
        /**
         * List of available locales for the currently selected market
         * @return {Array} List of available locales, for example ['en', 'fr']
         */
        marketAvailableLocales() {
            return this.$I18nService.getMarketAvailableLocales(this.selectedMarket);
        },
        /**
         * 
         */
        canSubmit() {
            return this.selectedMarket && this.selectedLocale;
        }
    },
    watch: {
        $route: function(to, from) {
            // Wait for next tick to avoid early locale update. 
            // TODO: 
            // this should be fixed by migrating the whole locale and market management to a Vuex store,
            // and using native Vue reactivity for every change.
            this.$nextTick(() => {
                this.updateMarketAndLocale();
            });
        }
    },
    created: function() {
        this.updateMarketAndLocale();
    },
    methods: {
        updateMarketAndLocale() {
            this.currentLocale = this.$I18nService.getCurrentLocale();
            this.currentMarket = this.$I18nService.getCurrentMarket();
            this.selectedLocale = this.$I18nService.getCurrentLocale();
            this.selectedMarket = this.$I18nService.getCurrentMarket();
        },
        onMarketChange() {
            // Update locales lists according to market, and preselect default locale
            this.selectedLocale = this.$I18nService.getMarketDefaultLocale(this.selectedMarket);
        },
        onLocaleChange() {
            //
        },
        onButtonClick() {
            this.updateMarketAndLocale();
            this.displayModal = true;
        },
        onModalClose() {
            this.displayModal = false;
        },
        /**
         * Define new market & modal on I18nService
         */
        onValidate() {
            if (this.canSubmit) {
                this.processing = true;
                this.$I18nService.setMarket(this.selectedMarket, this.selectedLocale)
                    .then(() => {
                        this.processing = false;
                        this.displayModal = false;
                        // Allways redirect through Router.
                        // - Redirect to homepage by default if were on a 'service' page, as we have no 
                        //   guaranty that this service will still be available after market change
                        // - Flush query parameters, to avoid irrelevant locale persistance
                        const routeName = this.$route.name === RouteNames.BOOK_SERVICE ? RouteNames.HOME : this.$route.name;
                        this.$router.push({
                            name: routeName,
                            params: {
                                market: this.selectedMarket,
                                division: this.$route.params.division
                            },
                            query: null
                        }).finally(() => {
                            // Catching the forced redirect error: 
                            // same market so same URL, but potentially different locale
                            // so we force page reload to make sure contents are updated.
                            this.$router.go()
                                .catch((error) => {
                                    // Catching the forced redirect error. Everything is fine.
                                });
                        });
                    });
            }
        }
    }

};
</script>
