import * as getters from '@/store/auth/getters';
var state = function () { return ({
    accessToken: String(localStorage.getItem('token') || ''),
    accessTokenExpiration: Number(localStorage.getItem('expiration') || 0),
    user: 'fake user'
}); };
export default {
    state: state,
    namespaced: true,
    getters: getters
    // mutations,
    // actions,
};
