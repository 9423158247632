/**
 * Tracker
 * Sends analytics data, pageviews and events, to dataLayer object 
 */
class _Tracker {
    /**
     * 
     */
    constructor() {
        this._defaultVariables = {};
        this._debug = false;
    }

    /**
     * 
     * @param {object} params 
     */
    setDefault(params) {
        this._defaultVariables = { ...this._defaultVariables, ...params };
    }

    /**
     * Tracking virtual page view
     * Expected minimum params :
     * - page_name
     * - virtual_page_url
     * @param {object} params 
     */
    pageView(params) {
        let p = { ...this._defaultVariables, ...params };
        p.event = 'virtualpageview';
        const mKeys = Object.keys(p);
        for (const key of mKeys) {
            if (p[key]) {
                p[key] = String(p[key]).toLowerCase();
            }
        }
        if (this._debug) console.log('Tracker: pageView', p);
        this._push(p);
    }

    /**
     * Tracking event
     * @param {object} params 
     */
    event(params) {
        let p = params;
        p.event = 'uaevent';
        const mKeys = Object.keys(p);
        for (const key of mKeys) {
            p[key] = String(p[key]).toLowerCase();
        }
        if (this._debug) console.log('Tracker: event', p);
        this._push(p);
    }

    /**
     * Actually push event or page view to dataLayer
     * @param {object} obj 
     */
    _push(obj) {
        window.dataLayer.push(obj);
    }
}

const Tracker = new _Tracker();
export default Tracker;
