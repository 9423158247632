var _a;
import { SET_SERVICE_LIST, SET_SERVICE_THEMATIC_FILTER, SET_SERVICE_TYPE_FILTER } from '@/store/mutation-types';
export default (_a = {},
    /**
     * Store ServiceList
     * @param state vuex state
     * @param availability API-provided availability
     */
    _a[SET_SERVICE_LIST] = function (state, services) {
        state.services = services;
    },
    /**
     * Set thematic filter
     * @param state vuex state
     * @param thematicId selected thematic id to be used as services filter
     */
    _a[SET_SERVICE_THEMATIC_FILTER] = function (state, thematicId) {
        state.thematicFilter = thematicId;
    },
    /**
     * Set service type filter
     * @param state vuex state
     * @param serviceType selected service type to be used as services filter
     */
    _a[SET_SERVICE_TYPE_FILTER] = function (state, serviceType) {
        state.serviceTypeFilter = serviceType;
    },
    _a);
