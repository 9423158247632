/**
 * Custom storage, containing all user's and booking-related informations 
 */
const UserStore = {
    _debug: false,
    _state: {
        username: null,
        civility: null,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        optin: null,
        place: {
            name: null,
            geolocation: {
                lat: null,
                lng: null
            }
        },
        service: null,
        serviceType: null,
        subService: null,
        date: null,
        time: null,
        pointOfSale: null,
        loginKey: null,
        bookingId: null,
        bookingDetails: null,
        favorites: [],
        tryon: {
            allowedForTryOn: false,
            callDuration: 0
        }
    },

    getUser() {
        return {};
    },

    setUsername(value) {
        if (this._debug) console.log('UserStore.setUsername:', value);
        this._state.username = value;
    },

    getUsername() {
        return this._state.username;
    },

    setCivility(value) {
        if (this._debug) console.log('UserStore.setCivility:', value);
        this._state.civility = value;
    },

    getCivility() {
        return this._state.civility;
    },

    setFirstname(value) {
        if (this._debug) console.log('UserStore.setFirstname:', value);
        this._state.firstname = value;
    },

    getFirstname() {
        return this._state.firstname;
    },

    setLastname(value) {
        if (this._debug) console.log('UserStore.setLastname:', value);
        this._state.lastname = value;
    },

    getLastname() {
        return this._state.lastname;
    },

    setEmail(value) {
        if (this._debug) console.log('UserStore.setEmail:', value);
        this._state.email = value;
    },

    getEmail() {
        return this._state.email;
    },

    setPhone(value) {
        if (this._debug) console.log('UserStore.setPhone:', value);
        this._state.phone = value;
    },

    getPhone() {
        return this._state.phone;
    },

    setOptin(value) {
        if (this._debug) console.log('UserStore.setOptin:', value);
        this._state.optin = value;
    },

    getOptin() {
        return this._state.optin;
    },

    setPlace(value) {
        if (this._debug) console.log('UserStore.setPlace:', value);
        this._state.place = value;
    },

    getPlace() {
        return this._state.place;
    },

    setServiceType(value) {
        if (this._debug) console.log('UserStore.setServiceType:', value);
        this._state.serviceType = value;
    },

    getServiceType() {
        return this._state.serviceType;
    },

    setService(value) {
        if (this._debug) console.log('UserStore.setService:', value);
        this._state.service = value;
    },

    getService() {
        return this._state.service;
    },

    setSubService(value) {
        if (this._debug) console.log('UserStore.setSubService:', value);
        this._state.subService = value;
    },

    getSubService() {
        return this._state.subService;
    },

    setDate(value) {
        if (this._debug) console.log('UserStore.setDate:', value);
        this._state.date = value;
    },

    getDate() {
        return this._state.date;
    },

    setTime(value) {
        if (this._debug) console.log('UserStore.setTime:', value);
        this._state.time = value;
    },

    getTime() {
        return this._state.time;
    },

    setPointOfSale(value) {
        if (this._debug) console.log('UserStore.setPointOfSale:', value);
        this._state.pointOfSale = value;
    },

    getPointOfSale() {
        return this._state.pointOfSale;
    },

    setLoginKey(value) {
        if (this._debug) console.log('UserStore.setLoginKey:', value);
        this._state.loginKey = value;
    },

    getLoginKey() {
        return this._state.loginKey;
    },

    setBookingId(value) {
        if (this._debug) console.log('UserStore.setBookingId:', value);
        this._state.bookingId = value;
    },

    getBookingId() {
        return this._state.bookingId;
    },

    setBookingDetails(value) {
        if (this._debug) console.log('UserStore.setBookingDetails:', value);
        this._state.bookingDetails = value;
        this.setBookingId(value.id);
    },
    
    getBookingDetails() {
        return this._state.bookingDetails;
    },

    allowForTryOn(value) {
        if (this._debug) console.log('UserStore.allowForTryOn:', value);
        this._state.tryon.allowedForTryOn = value;
    },

    isAllowedForTryOn() {
        return this._state.tryon.allowedForTryOn;
    },

    setTryOnCallDuration(value) {
        if (this._debug) console.log('UserStore.setTryOnCallDuration:', value);
        this._state.tryon.callDuration = value;
    },

    getTryOnCallDuration(value) {
        return this._state.tryon.callDuration;
    },

    /**
     * Add product SKU to user's favorites. 
     * Memory storage only, no product details. 
     * @param {int} sku 
     */
    addFavoriteProduct(sku) {
        if (!this.isProductFavorite(sku)) {
            this._state.favorites.push(sku);
        }
    },

    /**
     * Delete product SKU from user's favorites
     * @param {int} sku 
     */
    deleteFavoriteProduct(sku) {
        let index = this._state.favorites.indexOf(sku);
        if (index > -1) {
            this._state.favorites.splice(index, 1);
        }
    },

    isProductFavorite(sku) {
        return this._state.favorites.indexOf(sku) > -1;
    },

    getFavoriteProductsList() {
        return this._state.favorites;
    },

    /**
     * Reset all user choices
     */
    resetAllUserChoices() {
        this.setPlace({
            name: null,
            geolocation: {
                lat: null,
                lng: null
            }
        });
        this.setService(null);
        this.setDate(null);
        this.setTime(null);
        this.setPointOfSale(null);
    }

};
export default UserStore;
