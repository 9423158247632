/**
 * Tools
 */
class Tools {
    /**
     * Convert a Date instance to string,
     * according to the format expected by the backend API 
     * @param {Date} date 
     * @return {String} the formatted date, following format '2022-11-30'
     */
    dateToAPIStringFormat(date) {
        let month = (date.getMonth() + 1);
        if (month < 10) month = '0' + month;
        let day = date.getDate();
        if (day < 10) day = '0' + day;
        return date.getFullYear() + '-' + month + '-' + day;
    }

    /**
     * Convert a Date instance to human readable format, 
     * according to given locale.
     * @param {Date} date 
     * @return {String} the parsed date, following format "mercredi 30 mars 2022"
     */
    dateToHumanReadableFormat(date, locale = 'fr-FR') {
        let options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' };
        return date.toLocaleDateString(locale, options);
    }

    /**
     * Convert a Date instance to a short human readable format, 
     * according to given locale.
     * @param {Date} date 
     * @return {String} the parsed date, following format "30 mars 2022"
     */
    dateToShortHumanReadableFormat(date, locale = 'fr-FR') {
        let options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(locale, options);
    }

    /**
     * Convert a Date instance to basic string
     * @param {Date} date 
     * @return {String} the formatted date, following format '30/11/2022'
     */
    dateToBasicFormat(date) {
        return [
            this.padToTwoDigits(date.getDate()),
            this.padToTwoDigits(date.getMonth() + 1),
            date.getFullYear()
        ].join('/');
    }

    /**
     * Pad a number to 2 digits and convert to string.
     * For example, 3 will be padded to '03'
     * @param {Number} num the number to pad
     * @returns {String} the padded stringified number
     */
    padToTwoDigits(num) {
        return num.toString().padStart(2, 0);
    }

    /**
     * Validate email address
     * @param {string} email 
     */
    validateEmailAddress(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    
    /**
     * Convert raw amount of seconds into MM:SS (02:57) format 
     * @param {Number} rawSeconds 
     */
    secondsToMMSS(rawSeconds) {
        let minutes = Math.floor(rawSeconds / 60);
        let seconds = Math.round(rawSeconds % 60);
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        return minutes + ':' + seconds;
    }

    /**
     * Check if the device is a wide device format
     * @returns {Boolean} true weither app is displayed on a wide device
     */
    matchesWideDevice() {
        return window.matchMedia('(min-width: 768px)').matches;
    }

    /**
     * Copy string 'str' to clipboard
     * @param {string} str 
     */
    copyToClipboard(str) {
        let success = true;
        let textarea = document.createElement('textarea');
        textarea.style.cssText = 'position:fixed;pointer-events:none;z-index:-9999;opacity:0;';
        textarea.value = str;
        document.body.appendChild(textarea);
        // Special iOS case, see https://stackoverflow.com/a/34046084/1310132
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
            textarea.contentEditable = true;
            textarea.readOnly = true;
            const range = document.createRange();
            range.selectNodeContents(textarea);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textarea.setSelectionRange(0, 999999);
        } else {
            textarea.select();
        }
        try {
            document.execCommand('copy');
        } catch (e) {
            success = false;
            console.warn('Tools.copyToClipboard error:', e);
        }
        document.body.removeChild(textarea);
        // 
        return success;
    }
}

export default new Tools();
