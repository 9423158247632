/**
 * 
 * I18nDivisionHelper mixin
 * Help getting division-dedicated translations. 
 * Usage:
 * - add mixin to component
 * - call with this.$td('my-translation-key');
 * - also provide shortcut for pluralization with this.$tcd('my-translation-key', N); 
 */

import { divisionFromSeoName } from '@/utils/DivisionsHelper';

const I18nDivisionHelper = {
    methods: {
        /**
         * Get division-dedicated translation
         * @param {string} key the translation key
         * @param {number} count the amount of items for pluralization
         * @param {string} defaultText the default text to return if no translation is found
         * @returns translated string
         */
        $td(key, count = 0, defaultText = '') {
            const divisionSeoName = this.$route.params.division;
            const division = divisionFromSeoName(divisionSeoName) || { code: 'fnb' };
            const divKey = `${division.code.toLowerCase()}-${key}`;
            const translated = this.$tc(divKey, count, { default: defaultText });
            if (defaultText !== '') {
                return translated === divKey ? defaultText : translated;
            }
            return count ? this.$t(divKey) : this.$tc(divKey, count);
        },
        /**
         * Pluralization shorcut for division-dedicated translations
         * @param {string} key the translation key
         * @param {*} count the amount of items for pluralization
         * @returns translated string
         */
        $tcd(key, count) {
            return this.$td(key, count);
        }
    }
};
export default I18nDivisionHelper;
