/**
 * 
 * Division mixin
 * - provide helper to get the current division, or fallback to default
 * 
 */
import { divisionFromSeoName } from '@/utils/DivisionsHelper';
import Config from '@/utils/config';

const Division = {
    computed: {
        /**
         * Get the current division object, or fallback to default
         * @returns {Division} the complete Division object
         */
        division() {
            const currentDivision = divisionFromSeoName(this.$route.params.division);
            const currentMarket = this.$route.params.market;

            if (currentDivision) {
                return divisionFromSeoName(this.$route.params.division);
            } else {
                // Otherwise, return the default division for the current market
                return Config.getDefaultDivisionByMarket(currentMarket);
            }
        },
        /**
         * Get the currend division SEO name
         * @returns {string} the current division SEO name, for example 'mode'
         */
        divisionSeoName() {
            return this.division?.seoName;
        }
    }
};
export default Division;
