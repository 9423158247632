<template>
    <section class="home container container-alt-lg">
        <div class="col px-lrg-0 pb_2 pb-4 mb-md-10">
            <h1 class="my-5 my-md-7 text-20 text-md-30 line-12 text_center c-black-light">
                <span v-html="$t('homepage-fashion-title')"></span><br/>
                <span class="text-12 text-md-16" v-html="$t('homepage-fashion-title-2')"></span>
            </h1>
            <div class="full_sm_sep_light">
                <div v-if="servicesLoading" class="text_center">
                    <loading-dots />
                </div>
                <template v-else-if="getServices && getServices.length > 0">
                    <h2 class="text-14 text_center my-5 my-md-7 c-black-light" v-html="$t('homepage-fashion-intro')"></h2>
                    <div class="d_md_flex f_md_wrap fg_md_2">
                        <!-- Services Loop -->
                        <service
                            v-for="service in getServices"
                            :key="service.id"
                            :service="service"
                            :singleBoutiqueId="singleBoutiqueId"
                            :singleBoutiqueSlug="singleBoutiqueSlug || null" />
                        <!-- Services Loop End -->
                    </div>
                </template>
                <div v-else class="content text_center pt-2 pb-1">
                    <p class="text-14 c-grey" v-html="$t('common-generic-error')"></p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Divisions } from '@/const/Divisions';
import { EventBus } from '@/utils/EventBus';
import API from '@/api';
import Page from '@/views/Page.js';
import RouteNames from '@/const/RouteNames';
import LoadingDots from '@/components/common/LoadingDots.vue';
import Service from '@/components/templates/fashion/homefashion/Service.vue';

export default {
    name: 'HomeFashion',
    metaInfo: function() {
        const meta = {
            title: this.$t('fsh-homepage-meta-title'),
            meta: [
                { name: 'description', content: this.$t('fsh-homepage-meta-description') }
            ]
        };
        if (this.boutiquesOnly || this.singleBoutiqueSlug) {
            // If scoped to Boutiques only, define a canonical URL to the generic homepage
            meta.link = [
                { rel: 'canonical', href: window.location.origin }
            ];
        }
        return meta;
    },
    mixins: [
        Page
    ],
    data: () => ({
        RouteNames,
        servicesLoading: false,
        singleBoutiqueId: null
    }),
    computed: {
        ...mapGetters('service', {
            getServices: 'getUnhiddenServices'
        }),
        /**
         * Scoped for single Boutique only
         * @return {string|boolean} single boutique slug like 'chanel-paris-51-montaigne', or false if not scoped to single boutique
         */
        singleBoutiqueSlug() {
            return this.$route.params.boutique ? this.$route.params.boutique : false;
        }
    },
    watch: {
        $route(to, from) {
            // Force reload the service list in case of market change,
            // as the market change can occur without re-mounting the full page.
            if (to.params.market !== from.params.market) {
                this.getServicesList();
            }
        }
    },
    components: {
        Service,
        LoadingDots
    },
    created: function() {
        // Check boutique existence if a single boutique slug is provided. 
        // This could be moved into a beforeRouteEnter navigation guard, 
        // but then we would lose the current URL.
        if (this.singleBoutiqueSlug) {
            API.getBoutiqueStatus(this.singleBoutiqueSlug)
                .then((result) => {
                    // All OK: store the chanelId for later navigation, and continue with services.
                    this.singleBoutiqueId = result.chanelId;
                })
                .catch((error) => {
                    EventBus.$emit('404-error');
                });
        }
        // Load services anyway
        this.getServicesList();
    },
    mounted() {
        this.$Tracker.pageView({
            page_name: 'homepage',
            sub_category_level1: 'appointment booking',
            sub_category_level2: 'homepage',
            virtual_page_url: this.$router.currentRoute.path,
            virtual_page_title: this.$t('fsh-homepage-meta-title')
        });
    },
    methods: {
        ...mapActions('service', [
            'fetchServiceList'
        ]),
        getServicesList() {
            this.servicesLoading = true;
            this.fetchServiceList({ divisionCode: Divisions.FSH.apiCode })
                .finally(() => {
                    this.servicesLoading = false;
                    // Page is complete: trigger render event
                    this.rendered();
                });
        }
    }
};
</script>
