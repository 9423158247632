/**
 * Configuration 
 */
import { DivisionsCodes } from '@/const/Divisions';
import { divisionFromApiCode, divisionFromCode } from '@/utils/DivisionsHelper';

class _Config {
    constructor() {
        // Default options
        this.options = {
            foo: 'default_bar',
            version: '0.0.0',
            prd_base_url: 'https://rendez-vous.chanel.com',
            api_root_url: 'https://apim.lpl-cloud.com/rendezvous',
            api_subscription_key: 'dca7733879a1403b9d8aa4e11abe9613',
            assets_base_url: 'https://int-assets-fr.chanel.com',
            google_maps_api_key: 'AIzaSyD_AE05_dEa_gchtDSz9QdsLBvyvarwBH8',
            i18n: {
                defaultLocale: 'fr',
                defaultMarket: 'fr',
                supportedMarkets: [
                    {
                        code: 'fr',
                        defaultLocale: 'fr',
                        availableLocales: ['fr'],
                        // List of divisions available for this market, noted as API codes ('PB', 'MODE', ...)
                        divisions: [
                            {
                                division: 'MODE',
                                defaultLocale: 'fr',
                                availableLocales: [
                                    'fr'
                                ]
                            }
                        ]
                    }
                ]

            },
            divisions: {
                defaultDivision: DivisionsCodes.FNB,
                defaultMarketsByDivisions: [
                    {
                        [DivisionsCodes.FSH]: 'fr',
                        [DivisionsCodes.FNB]: 'fr'
                    }
                ],
                supportedDivisons: [
                    DivisionsCodes.FNB,
                    DivisionsCodes.FSH,
                    DivisionsCodes.WFJ
                ]
            },
            ymk: {
                id: 'c1020053',
                key: 'PWUvCggsEwZYdqegFpZKw'
            }
        };
    }

    /**
     * Store custom configuration
     * @param {object} config 
     */
    setConfig(config) {
        this.options = { ...this.options, ...config };
        // console.log('Config.setConfig: registered', this.options);
    }

    /**
     * Store partial i18n config as 'supportedMarkets'
     * @param {array} supportedMarkets 
     */
    setSupportedMarkets(supportedMarkets) {
        this.options.i18n.supportedMarkets = [...supportedMarkets];
    }

    /**
     * Return generic configuration option
     * @param {*} option 
     */
    getOption(option) {
        return this.options[option];
    }

    getSupportedDivisions() {
        return this.options.divisions.supportedDivisons;
    }

    /**
     * Get supported divisions for given market
     * @param {String} market market two-letter code, for example 'fr'
     * @returns {Array} list of divisions API codes
     */
    getSupportedDivisionsByMarket(market = null) {
        let mkt = market || this.options.i18n.defaultMarket;
        return this.options.i18n.supportedMarkets.find((m) => m.code.toLowerCase() === mkt);
    }

    /**
     * Get supported divisions for all markets
     * @returns {Array{Object}} array of markets, like: [{ code: 'fr', divisions: ['MODE', 'BEAUTY']}]
     */
    getSupportedDivisionsByMarkets() {
        return this.options.i18n.supportedMarkets.map((m) => {
            const divisions = m.divisions.map((d) => d.division);
            return {
                code: m.code,
                divisions
            };
        });
    }

    /**
     * Returns a configuration array of markets,
     * with their codes and available locales, 
     * for the given division only.
     * @param {string} divisionApiCode the division API code, for example 'PB'
     * @return {array} complete markets configuration for given division. Example: 
     * [
     *      {
     *          code: 'FR',
     *          availableLocales: ['fr', 'en'],
     *          defaultLocale: 'fr'
     *      }
     *  ]
     */
    getI18nConfigByDivision(divisionApiCode) {
        const supportedMarkets = this.options.i18n.supportedMarkets.filter((market) => {
            // Filters markets which have the given division
            return market.divisions.filter((div) => div.division === divisionApiCode).length > 0;
        }).map((market) => {
            // Extract required division
            const div = market.divisions.filter((div) => div.division === divisionApiCode)[0];
            return {
                id: market.id,
                code: market.code,
                currency: market.currency,
                defaultLocale: div.defaultLocale,
                availableLocales: div.availableLocales
            };
        });
        return {
            defaultLocale: this.options.i18n.defaultLocale,
            defaultMarket: this.options.i18n.defaultMarket,
            supportedMarkets
        };
    }

    /**
     * Return the list of supported markets for given division, as flattened array.
     * @param {string} divisionApiCode the division API code, for example 'PB'
     * @return {array} example: ['fr', 'it']
     */
    getSupportedMarketsByDivision(divisionApiCode) {
        return this.getI18nConfigByDivision(divisionApiCode).supportedMarkets.map((market) => {
            return market.code;
        });
    }

    /**
     * 
     * @returns 
     */
    getDefaultDivision() {
        return this.options.divisions.defaultDivision;
    }

    /**
     * Get the default division for provided market. 
     * Will first use the general default division, 
     * then fallback to the first division available in market config
     * @param {String} market the market two-letters code, for example 'fr'
     * @returns {Division} the complete Division object
     */
    getDefaultDivisionByMarket(market) {
        let defaultDiv = divisionFromCode(this.getDefaultDivision());
        // Cover the case where market is not defined, as a (very) side use case
        if (market) {
            const marketConfig = this.options.i18n.supportedMarkets.find((m) => m.code.toLowerCase() === market.toLowerCase());
            if (marketConfig) {
                if (marketConfig.divisions.filter((div) => div.division === defaultDiv.apiCode).length > 0) {
                    return defaultDiv;
                } else {
                    defaultDiv = divisionFromApiCode(marketConfig.divisions[0].division);
                    return defaultDiv;
                }
            } else {
                return defaultDiv;
            }
        } else {
            return defaultDiv;
        }
    }

    /**
     * Get corporate-equivalent market & locale code, for the given market & locale.
     * For example, will return 'en_DK' for 'da_DK', based on the config.json content.
     * @param {String} marketLocaleCode the application market & locale code, for example 'da_DK'
     * @returns {String} fallback corporate market & locale code, for example 'en_DK'
     */
    getCorporateMarketLocaleCode(marketLocaleCode) {
        const fallbackConf = this.options.crp_locales_fallbacks;
        return fallbackConf[marketLocaleCode] || marketLocaleCode;
    }

    /**
     * Get dotcom-equivalent market & locale code, for the given market & locale.
     * For example, will return 'en_DK' for 'da_DK', based on the config.json content.
     * @param {String} marketCode the application market code, for example 'da'
     * @returns {String} fallback for dotcom market & locale code, for example 'en_DK'
     */
    getDotComMarketLocaleCode(marketCode) {
        const fallbackConf = this.options.dotcom_locales_fallbacks;
        return fallbackConf[marketCode] || marketCode;
    }

    setAppVersion(version) {
        this.options.version = version;
    }

    getAppVersion() {
        return this.options.version;
    }

    getApiRootUrl() {
        return this.options.api_root_url;
    }

    getApiSubscriptionKey() {
        return this.options.api_subscription_key;
    }

    /**
     * Always-Production front-end base URL.
     */
    getProductionBaseUrl() {
        return this.options.prd_base_url;
    }

    getGoogleMapsApiKey() {
        return this.options.google_maps_api_key;
    }

    getAssetsBaseUrl() {
        return this.options.assets_base_url;
    }
}

const Config = new _Config();
export default Config;
