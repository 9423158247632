<!--

CustomImage
Image component based on srcsets and Cloudinary transformations.

Usage:
<custom-image :data="imageData" :transformationNames="{ DESTKOP: 'rounded' }" />
or:
<custom-image :data="imageData" :transformations="{ DESTKOP: 'c_crop,h_600,w_600' }" />

Sample expected imageData format:
{
    "alt": "image alternative text",
    "sources": [
        {
            "src": "https://example.com/image.png",
            "templatedSrc": "https://example.com/{transformation}/image.png",
            "media": "DESKTOP",
            "transformations": [
                {
                    "name": "default",
                    "value": "w_800/f_png"
                },
                {
                    "name": "rounded",
                    "value": "w_800/f_png/r_max"
                }
            ]
        },
        {
            "src": "https://example.com/image-mobile.png",
            "templatedSrc": "https://example.com/{transformation}/image-mobile.png",
            "media": "MOBILE",
            "transformations": [
                {
                    "name": "default",
                    "value": "w_800/f_png"
                }
            ]
        }
    ]
},

Sample expected 'transformations' format:
{ DESKTOP: 'c_crop,h_600,w_600' }
- transformations are optional
- transformations have priority over transformationsNames
- key must match mediaQueries and sources (DESKTOP|MOBILE)
- value must be a valid Cloudinary transformation string

Sample expected 'transformationNames' format:
{ DESKTOP: 'rounded', MOBILE: 'default' }
- transformationNames are optional
- key must match mediaQueries and sources (DESKTOP|MOBILE)
- value must match with provided transformation names in data.

-->
<template>
    <picture>
        <source v-for="(source, index) in sources"
            :srcset="source.src"
            :media="mediaQueries[source.media]"
            :key="index">
        <img
            ref="image"
            class="image-waiting d_block" :class="faded ? 'faded' : ''"
            :src="defaultImageSrc"
            :loading="lazyload ? 'lazy' : 'eager'"
            :alt="data.alt"
        >
    </picture>
</template>

<script>
export default {
    data: function() {
        return {
            baseUrl: '/img/content/',
            defaultData: {
                alt: ''
            },
            mediaQueries: {
                DESKTOP: '(min-width: 768px)',
                MOBILE: '(max-width: 768px)'
            }
        };
    },
    props: {
        data: {
            type: Object,
            default: () => ({
                alt: '-',
                sources: [
                    {
                        media: 'DESKTOP',
                        src: 'no-image.jpg'
                    }
                ]
            })
        },
        /**
         * Request transformations, by media type. 
         * Media types must match with mediaQueries and sources.
         * Values must be valid Cloudinary transformations strings.
         * For example: 
         * { DESKTOP: 'w_1280/r_max', MOBILE: 'w_640/dpr_auto' }
         */
        transformations: {
            type: Object,
            default: () => {}
        },
        /**
         * Request transformation names, by media type. 
         * Media types must match with mediaQueries and sources.
         * Values must match with provided transformation names in data.
         * For example: 
         * { DESKTOP: 'rounded', MOBILE: 'default' }
         */
        transformationNames: {
            type: Object,
            default: () => {}
        },
        lazyload: {
            type: Boolean,
            default: true
        },
        faded: {
            type: Boolean,
            default: true
        },
        addBaseUrl: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        /**
         * Prepare image sources
         * @return {Array} array of image sources, for example:
         * [
         *      {
         *         media: 'DESKTOP',
         *         src: 'https://example.com/image.png'
         *      }
         * ]
         */
        sources() {
            const srcs = [];
            if (this.data?.sources) {
                this.data.sources.forEach(source => {
                    let src = source.src;
                    // If we have a custom transformation request for this type of media (DESKTOP / MOBILE),
                    // then we will directly apply this specific transformation in templatedSrc. 
                    // Otherwise, if we have a transformationName request for this type of media, 
                    // then we will check if this transformation matches with some transfo in the 'data'.
                    // For example: 
                    // { DESKTOP: 'rounded' } : will match with 'rounded' transformation in data 
                    if (this.transformations && this.transformations.hasOwnProperty(source.media)) {
                        src = source.templatedSrc.replace('{transformation}', this.transformations[source.media]);
                    } else if (this.transformationNames && this.transformationNames.hasOwnProperty(source.media)) {
                        const transformationRequest = this.transformationNames[source.media];
                        if (source.transformations && source.transformations.length > 0) {
                            const transformationFound = source.transformations.find(transfo => transfo.name === transformationRequest);
                            if (transformationFound) {
                                src = source.templatedSrc.replace('{transformation}', transformationFound.value);
                            }
                        };
                    }
                    srcs.push({ src, media: source.media });
                });
            }
            return srcs;
        },
        defaultImageSrc() {
            let obj = Object.assign(this.defaultData, this.data);
            let src = obj.sources[0].src;
            if (!src.includes('http') && this.addBaseUrl) {
                src = this.baseUrl + src;
            }
            return src;
        }
    },
    mounted() {
        this.$refs.image.addEventListener('load', this.onImageLoaded);
        if (this.$refs.image.complete) {
            this.onImageLoaded();
        }
    },
    beforeDestroy() {
        this.$refs.image.removeEventListener('load', this.onImageLoaded);
    },
    methods: {
        onImageLoaded() {
            this.$refs.image.removeEventListener('load', this.onImageLoaded);
            this.$refs.image.classList.remove('image-waiting');
            this.$refs.image.classList.add('image-loaded');
            this.$emit('image-loaded');
        }
    }
};
</script>
