var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { findServiceOptionsPrice } from '@/services/helpers/ServiceModelHelpers';
dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * Get unfiltered list of Point of Sales
 * @param state vuex Booking state
 * @returns unfiltered list of Point of Sales
 */
export var getAllPointOfSales = function (state) {
    return state.availability ? state.availability.posDetails : [];
};
/**
 * Get filtered available Point of Sales,
 * according to selected Date
 * @param state vuex Booking state
 * @returns array of available Point of Sales
 */
export var getAvailablePointOfSales = function (state) {
    var _a, _b;
    // Check whether some Date have been selected, and filter accordingly.
    // Return only PoS for which we can find some slots for the selected Date.
    var selectedIsoDate = (_a = state.selectedDate) === null || _a === void 0 ? void 0 : _a.isoDate;
    var uniquePosIds = [];
    if (state.availability && selectedIsoDate) {
        // Proces only dates matching the selected date
        var dates = state.availability.dates.filter(function (d) { return d.isoDate === selectedIsoDate; });
        if (dates.length > 0) {
            // Loop through dates, process to have an array of unique POS IDs
            uniquePosIds = dates.reduce(function (stack, aDate) {
                var availableTimes = aDate.times;
                // If we have some selectedTime, then we filter the times list
                // and make sure we only have slots that match this selectedTime
                if (state.selectedTime) {
                    availableTimes = aDate.times.filter(function (time) {
                        var _a;
                        return time.isoTime === ((_a = state.selectedTime) === null || _a === void 0 ? void 0 : _a.isoTime);
                    });
                }
                // Loop through times
                var timesReduced = availableTimes.reduce(function (tStack, tTime) {
                    // Extract posId's from slots. Make sure they are unique & stack them.
                    var slotsReduced = tTime.pos.reduce(function (sStack, slot) {
                        if (sStack.indexOf(slot.posId) === -1) {
                            sStack.push(slot.posId);
                        }
                        return sStack;
                    }, tStack);
                    return slotsReduced;
                }, stack);
                return timesReduced;
            }, uniquePosIds);
        }
        // Use the array of unique POS IDs to filter the posDetails accordingly
        return state.availability.posDetails.filter(function (posResult) { return uniquePosIds.includes(posResult.pos.id); });
    }
    else {
        return ((_b = state.availability) === null || _b === void 0 ? void 0 : _b.posDetails) || [];
    }
};
/**
 * Get unfiltered list of Booking Dates
 * @param state vuex Booking state
 * @returns unfiltered list of Booking Dates
 */
export var getAllDates = function (state) {
    return state.availability ? state.availability.dates : [];
};
/**
 * Get filtered available Dates,
 * according to selected Point of Sale
 * @param state vuex Booking state
 * @returns array of available dates
 */
export var getAvailableDates = function (state) {
    var _a, _b;
    // Check whether some PoS have been selected, and filter accordingly.
    // Filter should go deep into the data structure, to check if posId matches at the "BookingSlot" level
    var selectedPosId = (_a = state.selectedPointOfSale) === null || _a === void 0 ? void 0 : _a.id;
    if (state.availability && selectedPosId) {
        // Loop through dates, stack only dates with matching criterias
        var dates = state.availability.dates.reduce(function (stack, aDate) {
            // Loop through times, stack only times with matching criterias
            var times = aDate.times.reduce(function (tStack, tTime) {
                // Check for Booking Slots to see wheither they match with the selected posId
                var matchingSlots = tTime.pos.filter(function (pos) { return pos.posId === selectedPosId; });
                // If we found matching slots, assign them to the current tTime and stack the tTime
                return matchingSlots.length === 0
                    ? tStack // no match, return unchanged stack
                    : tStack.concat(__assign(__assign({}, tTime), { pos: matchingSlots }));
            }, []);
            // If we have times with matching criterias, assign the to the current aDate and stack the aDate
            return times.length === 0
                ? stack // no match, return unchanged stack
                : stack.concat(__assign(__assign({}, aDate), { times: times }));
        }, []);
        // Return filtered dates
        return dates;
    }
    else {
        // Return full set of dates
        return ((_b = state.availability) === null || _b === void 0 ? void 0 : _b.dates) || [];
    }
};
/**
 * Get the availability request geolocation status
 * @param state vuex Booking state
 * @returns true if the availability request is geolocated
 */
export var isGeolocatedAvailability = function (state) {
    return Boolean(state.geolocatedAvailability);
};
/**
 * Get the selected date with Point of Sales filters applied
 * @param state vuex Booking state
 * @returns pre-filtered selected date
 */
export var getFilteredSelectedDate = function (state) {
    if (state.selectedDate) {
        // Find the matching 'selectedDate' into the availableDates array, 
        // so we benefit from the alerady-done POS filtering.
        var date = getAvailableDates(state).find(function (date) { var _a; return date.isoDate === ((_a = state.selectedDate) === null || _a === void 0 ? void 0 : _a.isoDate); });
        return date;
    }
    return undefined;
};
/**
 * Get available time slots, according to selected Date & selected Point of Sale
 * @param state vuex Booking state
 * @returns array of available times
 */
export var getAvailableTimes = function (state) {
    var _a;
    // No available time if we don't have any selected date. 
    return ((_a = getFilteredSelectedDate(state)) === null || _a === void 0 ? void 0 : _a.times) || [];
};
/**
 * Get future available time slots, according to selected Date & selected Point of Sale
 * (get rid of past times)
 * @param state vuex Booking state
 * @returns array of future available times
 */
export var getFutureAvailableTimes = function (state) {
    var _a;
    // No available time if we don't have any selected date. 
    var times = ((_a = getFilteredSelectedDate(state)) === null || _a === void 0 ? void 0 : _a.times) || [];
    // If selected date is today, then return only future times
    if (state.selectedDate && dayjs(state.selectedDate.isoDate).isSame(dayjs(), 'day')) {
        // Filter out past times
        times = times.filter(function (time) {
            var splitTime = time.isoTime.split(':');
            var timeDate = new Date();
            timeDate.setHours(Number(splitTime[0]));
            timeDate.setMinutes(Number(splitTime[1]));
            return timeDate.getTime() > Date.now();
        });
    }
    return times;
};
/**
 * Get the farest available date amongst all filtered dates
 * @param state vuex Booking state
 * @returns farest future available date, or today
 */
export var getFarestFutureAvailableDate = function (state) {
    var dates = getAvailableDates(state);
    var date = new Date();
    if (dates.length > 0) {
        date = new Date(dates[dates.length - 1].isoDate);
    }
    return date;
};
/**
 * Get the closest available date amongst all filtered dates
 * @param state vuex Booking state
 * @returns closest future available date, or today
 */
export var getClosestFutureAvailableDate = function (state) {
    var dates = getAvailableDates(state);
    var date = new Date();
    if (dates.length > 0) {
        date = new Date(dates[0].isoDate);
    }
    return date;
};
/**
 * Get the selected date as Date object
 * @param state vuex Booking state
 * @returns selected date, parsed as Date object
 */
export var getSelectedParsedDate = function (state) {
    var _a;
    // Force stored raw date to be interpreted from UTC timezone.
    // For example, string '2022-05-25' (provided by API) will be interpreted as '2022-05-25T00:00:00.000'
    // no matter the browser's local timezone.
    var utcDateString = dayjs.utc((_a = state.selectedDate) === null || _a === void 0 ? void 0 : _a.isoDate).format('YYYY-MM-DDTHH:mm:ss');
    return state.selectedDate ? new Date(utcDateString) : undefined;
};
/**
 * Get the end date of selected timeslot
 * @param state vuex Booking state
 * @returns End date of selected timeslot, according to selected time and selected service
 */
export var getSelectedParsedEndDate = function (state) {
    if (state.selectedDate && state.selectedTime) {
        // Split hours and minutes from isoTime "13:45"
        var splitTime = state.selectedTime.isoTime.split(':');
        var selectedDate = getSelectedParsedDate(state);
        if (selectedDate) {
            // Clone selected date
            var endDateTime = new Date(selectedDate.getTime());
            endDateTime.setHours(Number(splitTime[0]));
            endDateTime.setMinutes(Number(splitTime[1]));
            // Add clientDuration
            if (state.selectedService) {
                endDateTime.setTime(endDateTime.getTime() + (state.selectedService.clientDuration * 1000 * 60));
                return endDateTime;
            }
        }
    }
    return undefined;
};
/**
 * Get the selected subservice object
 * @param state vuex Booking state
 */
export var getSelectedSubService = function (state) {
    var _a;
    if (state.selectedService && state.selectedSubService) {
        return (_a = state.selectedService.subServices) === null || _a === void 0 ? void 0 : _a.find(function (sSvc) { return String(sSvc.id) === String(state.selectedSubService); });
    }
    return undefined;
};
/**
 * Get the booking ID matching the selected date, time and POS.
 * The currently selected time can contain only one slot,
 * as other parameters must be selected before.
 * @param state vuex Booking state
 * @returns booking ID matching selected params
 */
export var getSelectedBookingSlotId = function (state) {
    if (state.selectedTime && state.selectedTime.pos[0]) {
        return state.selectedTime.pos[0].bookingId;
    }
    return undefined;
};
/**
 * Get a flattened array of PointOfSale from the PointOfSaleResult list
 * (get rid of the distance attribute)
 * @param state vuex Booking state
 * @returns flattened array of PointOfSale
 */
export var getPointOfSaleList = function (state) {
    var _a;
    return ((_a = state.pointOfSaleResultList) === null || _a === void 0 ? void 0 : _a.map(function (posResult) { return posResult.pos; })) || [];
};
/**
 * Get a raw array of PointOfSaleResult list
 * (preserve the the distance attribute)
 * @param state vuex Booking state
 * @returns array of PointOfSaleResult
 */
export var getPointOfSaleResultList = function (state) {
    return state.pointOfSaleResultList || [];
};
/**
 * Tracking-dedicated helper
 * Returns a "code", that can either be:
 * - the selected subservice code if any,
 * - or the selected service custom metadata if any,
 * - or the selected service ID
 * @param state vuex Booking state
 * @returns service ID, subservice code, or undefined
 */
export var getSelectedServiceTrackingCode = function (state) {
    var _a, _b, _c, _d, _e;
    var subservice = state.selectedSubService
        ? (_b = (_a = state.selectedService) === null || _a === void 0 ? void 0 : _a.subServices) === null || _b === void 0 ? void 0 : _b.find(function (sub) { return sub.id === state.selectedSubService; }) : null;
    var code;
    // No oneliner here for readability. 
    if (subservice) {
        code = subservice.code;
    }
    else if ((_d = (_c = state.selectedService) === null || _c === void 0 ? void 0 : _c.metadata) === null || _d === void 0 ? void 0 : _d.trackingCode) {
        code = state.selectedService.metadata.trackingCode;
    }
    else {
        code = (_e = state.selectedService) === null || _e === void 0 ? void 0 : _e.id;
    }
    return code;
};
/**
 * Get the selected date as Date object
 * @param state vuex Booking state
 * @returns selected date, parsed as Date object
 */
export var getCurrentBookingSelectedParsedDate = function (state) {
    var _a, _b;
    // Force stored raw date to be interpreted from UTC timezone.
    // For example, string '2022-05-25' (provided by API) will be interpreted as '2022-05-25T00:00:00.000'
    // no matter the browser's local timezone.
    var utcDateString = dayjs.utc((_a = state.currentBooking) === null || _a === void 0 ? void 0 : _a.bookingDay).format('YYYY-MM-DDTHH:mm:ss');
    return ((_b = state.currentBooking) === null || _b === void 0 ? void 0 : _b.bookingDay) ? new Date(utcDateString) : undefined;
};
/**
 * Get the selected time as string (HH:mm)
 * @param state vuex Booking state
 * @returns
 */
export var getCurrentBookingSelectedTime = function (state) {
    var _a;
    var time = (_a = state.currentBooking) === null || _a === void 0 ? void 0 : _a.bookingTime;
    return time ? time.split(':').slice(0, 2).join(':') : undefined;
};
/**
 * Get the selected service price, taking into account subservice and selected options if applicable
 * @param state vuex Booking state
 * @returns price for selected service & options
 */
export var getSelectedServiceOptionsPrice = function (state) {
    var _a;
    if (!state.selectedService) {
        return 0;
    }
    // By default, return the service price 
    var price = state.selectedService.price;
    // If a subservice is selected and this subservice has options, 
    // then we need to find the matching price combination
    if (state.selectedSubService) {
        var subservice = (_a = state.selectedService.subServices) === null || _a === void 0 ? void 0 : _a.find(function (sSvc) { return String(sSvc.id) === String(state.selectedSubService); });
        if (subservice && subservice.options && state.selectedSubServiceCategory1OptionId) {
            price = findServiceOptionsPrice(state.selectedService, Number(subservice.id), state.selectedSubServiceCategory1OptionId, state.selectedSubServiceCategory2OptionId);
        }
    }
    return price;
};
