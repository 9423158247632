<template>
    <div class="d_flex a_center">
        <div class="select p_rel">
            <custom-select
                :options="thematicsOptions"
                :defaultLabel="$t('servicelist-filter-category')"
                @input="onThematicFilterChange"
            />
        </div>
    </div>
</template>

<script>
import CustomSelect from '@/components/common/CustomSelect.vue';
import ServiceTypes from '@/const/ServiceTypes';

export default {
    data: () => ({
        ServiceTypes,
        serviceTypeFilter: 'undefined',
        thematicFilter: 'undefined'
    }),
    props: {
        thematics: {
            type: Array, // Array<String>,
            default: () => []
        }
    },
    computed: {
        thematicsOptions() {
            const options = this.thematics.map(thematic => ({
                value: thematic.code,
                label: thematic.name
            }));
            options.unshift({
                value: undefined,
                label: this.$t('servicelist-filter-category-all')
            });
            return options;
        }
    },
    components: {
        CustomSelect
    },
    methods: {
        onServiceTypeFilterChange(event) {
            this.$emit('update:type', event.target.value);
        },
        onThematicFilterChange(value) {
            this.$emit('update:thematic', value);

            // ----------
            this.$Tracker.event({
                eventcategory: 'rdv chanel_navigation',
                eventlabel: value,
                eventaction: 'moodboard menu'
            });
        }
    }
};
</script>
