import Vue from 'vue';
import Vuex from 'vuex';
import AuthModule from '@/store/auth/AuthModule';
import BookingModule from '@/store/booking/BookingModule';
import ServiceModule from './service/ServiceModule';
Vue.use(Vuex);
/**
 * Export multi-module store
 */
export default new Vuex.Store({
    modules: {
        auth: AuthModule,
        booking: BookingModule,
        service: ServiceModule
    }
});
