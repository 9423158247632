<template>
    <component v-bind:is="currentHomeTemplate" v-cy-helper="{ division: divisionSeoName, template: currentHomeTemplate }" />
</template>

<script>
import Page from '@/views/Page.js';
import Division from '@/mixins/Division.js';
import { DivisionsCodes } from '@/const/Divisions';
import CyHelper from '@/directives/CyHelper';
import HomeBeauty from '@/components/templates/beauty/HomeBeauty.vue';
import HomeFashion from '@/components/templates/fashion/HomeFashion.vue';
import HomeWFJ from '@/components/templates/wfj/HomeWFJ.vue';

export default {
    name: 'home',
    mixins: [
        Page,
        Division
    ],
    directives: {
        CyHelper
    },
    components: {
        HomeBeauty,
        HomeFashion,
        HomeWFJ
    },
    computed: {
        /**
         * Template switching according to division
         */
        currentHomeTemplate() {
            let template = 'HomeBeauty';
            switch (this.division ? this.division.code : null) {
            case DivisionsCodes.FNB:
                template = 'HomeBeauty';
                break;
            case DivisionsCodes.FSH:
                template = 'HomeFashion';
                break;
            case DivisionsCodes.WFJ:
                template = 'HomeWFJ';
                break;
            default:
                break;
            }
            return template;
        }
    }
};
</script>
