<template>
<div :dir="$I18nService.currentLocaleDirection()" id="app" :class="appClassList">
    <localizer v-on:before-enter="onTransitionBeforeEnter" class="app-content-wrapper">
        <transition name="page-fade" mode="out-in">
            <navigation-header
                v-if="hasNavigationHeader"
                :clickable="!retailSpecial">
            </navigation-header>
        </transition>

        <main>
            <not-found-page v-if="hasError404" :message="errorMessage" />
            <transition name="page-fade" mode="out-in"
                v-else
                v-on:before-enter="onTransitionBeforeEnter">
                <router-view :retailSpecial="retailSpecial" />
            </transition>
        </main>

        <navigation-footer v-bind:retailSpecial="retailSpecial"></navigation-footer>

        <locale-selector />
    </localizer>
</div>
</template>

<script>
import Config from '@/utils/config.js';
import RouteNames from '@/const/RouteNames';
import { Divisions, DivisionsCodes } from '@/const/Divisions';
import { divisionFromSeoName, divisionFromApiCode } from '@/utils/DivisionsHelper';
import Division from '@/mixins/Division.js';
import I18nDivisionHelper from '@/mixins/I18nDivisionHelper';
import UserStore from './utils/userstore.js';
import Localizer from '@/components/Localizer.vue';
import NavigationHeader from '@/components/nav/NavigationHeader.vue';
import NavigationFooter from '@/components/nav/NavigationFooter.vue';
import LocaleSelector from '@/components/nav/LocaleSelector.vue';
import NotFoundPage from '@/views/404.vue';
import { EventBus } from '@/utils/EventBus';

export default {
    name: 'App',
    metaInfo: function() {
        return {
            title: this.$td('common-meta-title', 0, 'CHANEL - Rendez-vous avec CHANEL'),
            titleTemplate: this.$td('common-meta-title-template', 0, 'CHANEL - Rendez-vous avec CHANEL'),
            meta: [
                { name: 'description', content: this.$t('homepage-meta-description') },
                { property: 'og:type', content: 'website' },
                { property: 'og:title', content: this.$td('common-meta-title') },
                { property: 'og:url', content: `${Config.getProductionBaseUrl()}${this.$route.path}` },
                { vmid: 'og_description', property: 'og:description', content: this.$t('homepage-meta-description') },
                { vmid: 'og_image', property: 'og:image', content: this.bannerImageURL }
            ]
        };
    },
    mixins: [
        I18nDivisionHelper,
        Division
    ],
    components: {
        Localizer,
        NavigationHeader,
        NavigationFooter,
        LocaleSelector,
        NotFoundPage
    },
    data: function() {
        return {
            splitScreenTemplates: [
                RouteNames.CONTACT,
                RouteNames.BOOK,
                RouteNames.BOOK_SERVICE,
                RouteNames.BOOK_CANCEL,
                RouteNames.BOOK_CONFIRM,
                RouteNames.RETAIL_BOOK_CANCEL,
                RouteNames.TRYON,
                RouteNames.TRYON_CLOSED,
                RouteNames.VOC
            ],
            routerDivisionsByMarkets: [],
            appClassList: [],
            retailSpecial: false,
            previousMakert: undefined,
            previousDivision: undefined,
            hasError404: false,
            errorMessage: ''
        };
    },
    computed: {
        /**
         * Navigation Header will be hidden on homepage for FNB division
         */
        hasNavigationHeader() {
            const currentRoute = this.$router.currentRoute.name;
            const currentDivision = divisionFromSeoName(this.$route.params.division);
            const isHome = [
                RouteNames.HOME,
                RouteNames.HOME_BOUTIQUE,
                RouteNames.HOME_BOUTIQUES,
                RouteNames.HOME_WHOLESALE_GROUP
            ].includes(currentRoute);
            return this.hasError404 || !(isHome && currentDivision && currentDivision.code === Divisions.FNB.code);
        },
        /**
          * Meta banner image, according to division
          */
        bannerImageURL() {
            let image = `${Config.getAssetsBaseUrl()}/f_auto,w_1080/medias/rdv-beaute/website/fnb/beauty-global-cropped-20220505-01.png`;
            if (this.division.code === DivisionsCodes.FSH) {
                image = `${Config.getAssetsBaseUrl()}/f_auto,w_1080/medias/rdv-beaute/website/fsh/fashion-global-cropped-20220427-01.png`;
            }
            return image;
        }
    },
    watch: {
        $route: function(to, from) {
            // Reset 404 state
            this.hasError404 = false;
            this.retailSpecial = this.$router.currentRoute.name === RouteNames.RETAIL_BOOK_CANCEL;
            // Check for compatibility between market and route
            this.checkMarketDivisionCompatibility();
            // If needed, update tracker defaults according to market and division
            this.updateTrackerDefault();
        },
        /**
         * Watch change on division:
         * make sure the I18nService is properly initialized with the actually active division.
         * @param {Division} to target division configuration
         * @param {Division} from previous division configuration
         */
        division: function(to, from) {
            if (to?.apiCode !== from?.apiCode) {
                this.$I18nService.init(Config.getI18nConfigByDivision(to.apiCode));
            }
        }
    },
    created: function() {
        // As Route is known and division can be determined, setup I18nService configuration
        this.$I18nService.init(Config.getI18nConfigByDivision(this.division.apiCode));

        UserStore.getUser();
        // Register event listeners on EventBus 
        EventBus.$on('404-error', this.on404Error);
        // Register supported divisions by markets
        const divisionsByMarkets = Config.getSupportedDivisionsByMarkets();
        // Convert divisions API codes to router's usable SEO names, and store locally
        this.routerDivisionsByMarkets = divisionsByMarkets.map((market) => {
            return {
                code: String(market.code).toLowerCase(),
                divisions: market.divisions.map(div => divisionFromApiCode(div)?.seoName)
            };
        });
    },
    beforeDestroy: function() {
        EventBus.$off('404-error', this.on404Error);
    },
    mounted: function() {
        this.checkMarketDivisionCompatibility();
        this.updateClassList();
        this.updateTrackerDefault();
    },
    methods: {
        /**
         * Check compatibility between market and division 
         * Will raise the 404 flag if incompatible. 
         * 
         * TODO:
         *      This could be moved into a beforeRouteEnter navigation guard,
         *      but then we would lose the current URL.
         *      Should be checked and refactored with a Vue Router upgrade to V4.
         */
        checkMarketDivisionCompatibility() {
            // Don't process if route name is not identified yet, to avoid false negative.
            if (this.$route.name) {
                const market = this.$route.params.market;
                const division = this.$route.params.division;
                const divsByCurrentMarket = this.routerDivisionsByMarkets.find((m) => m.code === market);
                if (!divsByCurrentMarket || (divsByCurrentMarket && !divsByCurrentMarket.divisions.includes(division))) {
                    console.warn(`ERROR: no compatibility between market & division: ${market}/${division}`);
                    EventBus.$emit('404-error');
                };
            }
        },
        /**
         * Update Tracker with default parameters,
         * according to current market and division.
         */
        updateTrackerDefault() {
            const market = this.$route.params.market;
            const division = this.$route.params.division;
            if (this.previousMakert !== market || this.previousDivision !== division) {
                this.previousMakert = market;
                this.previousDivision = division;
                const currentDivision = divisionFromSeoName(division);
                if (currentDivision) {
                    this.$Tracker.setDefault({
                        division: currentDivision.code.toLowerCase(),
                        division_flag: currentDivision.code.toLowerCase(),
                        region_chanel: 'europe',
                        locale: this.$I18nService.getCurrentLocale().toLowerCase(),
                        page_type: 'service',
                        content_type: 'services',
                        //
                        site: 'chanel rdv',
                        country: market,
                        language: this.$I18nService.getCurrentLocale().toLowerCase(),
                        site_device_type: window.screen < 768 ? 'mobile' : 'desktop'
                    });
                }
            }
        },
        /**
         * 
         */
        updateClassList() {
            this.appClassList = [];
            let currentRoute = this.$router.currentRoute.name;
            this.appClassList.push('route_' + currentRoute);
            this.appClassList.push(`app--${this.$I18nService.currentLocaleDirection()}`);

            // Special case for home-related routes:
            if ([
                RouteNames.HOME_BOUTIQUE,
                RouteNames.HOME_BOUTIQUES,
                RouteNames.HOME_WHOLESALE_GROUP
            ].includes(currentRoute)) {
                this.appClassList.push('route_home');
            }
            if (this.splitScreenTemplates.includes(currentRoute)) {
                this.appClassList.push('split_screen');
            }
        },
        onTransitionBeforeEnter: function() {
            this.updateClassList();
        },
        /**
         * Error event handler
         */
        on404Error: function(params) {
            this.hasError404 = true;
            this.errorMessage = params?.message ? params?.message : null;
        }
    }
};
</script>
