import Vue from 'vue';
/**
 * Event Buggling Prototype 
 * emit the event on all parent components
 */
Vue.prototype.$bubble = function $bubble(eventName, ...args) {
    let component = this;
    do {
        component.$emit(eventName, ...args);
        component = component.$parent;
    } while (component);
};
