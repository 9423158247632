/**
 * Get access token
 * @param state the vuex object implementing IAuthState
 * @returns the access token
 */
export var getAccessToken = function (state) { return state.accessToken; };
/**
 * Get User
 * @param state the vuex object implementing IAuthState
 * @returns the complete User object
 */
export var getUser = function (state) { return state.user; };
/**
 * Get User's authentication status
 * @param state the vuex object implementing IAuthState
 * @returns true if access token exists and is not expired
 */
export var getUserAuthStatus = function (state) {
    var now = Date.now();
    return state.accessToken !== '' && state.accessTokenExpiration > now;
};
