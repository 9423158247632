import * as getters from '@/store/service/getters';
import * as actions from '@/store/service/actions';
import mutations from '@/store/service/mutations';
var state = function () { return ({
    services: [],
    thematicFilter: 'FRAGRANCE',
    serviceTypeFilter: 'instore'
}); };
/**
 * Store and manage everything service-related.
 */
export default {
    state: state,
    namespaced: true,
    getters: getters,
    mutations: mutations,
    actions: actions
};
