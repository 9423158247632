var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container p_rel"},[_c('div',{staticClass:"pb-8 mb-md-4"},[_c('div',{staticClass:"col pt-8 pb-8"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"content text_center pt-2 pb-1"},[_c('h1',{staticClass:"f_26_40 hn_style"},[_vm._v("404")]),_c('p',{staticClass:"mb-6"},[_vm._v(" "+_vm._s(_vm.message ? _vm.message : _vm.$t('common-generic-error'))+" ")]),_c('router-link',{staticClass:"btn btn_black mb-4 mt-md-2",attrs:{"to":{
                            name: _vm.RouteNames.HOME,
                            params: {
                                division: _vm.divisionSeoName,
                                market: this.$I18nService.getCurrentMarket()
                            }
                        }},nativeOn:{"click":function($event){return _vm.trackButtonClick('book an appointment')}}},[_vm._v(" "+_vm._s(_vm.$t('common-book-an-appointment'))+" ")])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }