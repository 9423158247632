/**
 * Find a service option price matching selected subservice and category options.
 * @param service the targeted service
 * @param subServiceId the selected sub-service ID
 * @param category1OptionId the selected category 1 option ID
 * @param category2OptionId the selected category 2 option ID
 * @returns the price matching provided parameters, or lowest price if no match is found, 0 if error
 */
export function findServiceOptionsPrice(service, subServiceId, category1OptionId, category2OptionId) {
    var _a, _b, _c;
    var price = 0;
    var subservice = (_a = service.subServices) === null || _a === void 0 ? void 0 : _a.find(function (sSvc) { return String(sSvc.id) === String(subServiceId); });
    if (subservice) {
        // By default, take the lowest price
        price = ((_b = subservice.options) === null || _b === void 0 ? void 0 : _b.priceCombinations.sort(function (a, b) {
            return a.price - b.price;
        })[0].price) || 0;
        // Find matching service price into the provided category options
        // Make sure that an undefined category2OptionId still matches with null in priceCombination
        var parsedCategory2OptionId_1 = category2OptionId || null;
        var priceCombination = (_c = subservice.options) === null || _c === void 0 ? void 0 : _c.priceCombinations.find(function (priceCombination) {
            return (priceCombination.category1OptionId === category1OptionId &&
                priceCombination.category2OptionId === parsedCategory2OptionId_1);
        });
        if (priceCombination) {
            price = priceCombination.price;
        }
    }
    return price;
}
;
