/**
 * 
 * Page mixin
 * - provide function to call when page is rendered 
 * - upcoming features ?
 * 
 */

var Page = {
    methods: {
        rendered() {
            document.dispatchEvent(new Event('app-rendered'));
        }
    }
};
export default Page;
