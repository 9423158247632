<template>
    <div class="card_wrapper" :class="odd ? 'odd' : ''">
        <div class="container">
            <div class="card card_cover d_flex fd_sm_column p_rel" @click="onCardClick">
                <div class="card_content d_flex col_md_6">
                    <div class="inner d_flex j_space_btw fd_column">
                        <div>
                            <picture class="chanel_badge">
                                <img src="@/assets/cc.svg" alt="">
                            </picture>
                            <h2 v-if="thematic" class="hn_style" v-html="thematic.name"></h2>
                        </div>
                        <p class="hn_style text-11 mb-0">{{ $t('fnb-services-cover-notice') }}</p>
                    </div>
                </div>
                <custom-image
                    v-if="thematic.mainImage"
                    :data="thematic.mainImage"
                    class="card_img set_ratio col_md_6"
                ></custom-image>
            </div>
        </div>
    </div>
</template>

<script>
import CustomImage from '@/components/common/CustomImage.vue';

export default {
    name: 'Cover',
    props: {
        thematic: {
            type: Object, // Thematic
            default: () => {}
        },
        odd: {
            type: Boolean,
            default: false
        }
    },
    components: {
        CustomImage
    },
    methods: {
        onCardClick() {
            this.$emit('click');
        }
    }
};
</script>
